export function objToQueryString(obj) {
    const keyValuePairs = [];
    Object.keys(obj).forEach(key => {
        keyValuePairs.push(`${key}=${encodeURIComponent(obj[key])}`);
    });
    return keyValuePairs.join('&');
}

export function queryStringToObject(queryString) {
    const keyValuePairs = queryString.split('&');
    const result = {};
    for (const pair of keyValuePairs) {
        const [key, value] = pair.split('=');
        const decodedValue = decodeURIComponent(value);
        result[key] = decodedValue;
    }
    return result;
}