import React from "react";
import _identity from "lodash/identity";
import cx from "classnames";
import "./Input.scss";

const Input = (props) => {
    const {
        className = "",
        style = {},
        handleResetField = _identity,
        placeholder,
        label,
        disabled,
        error,
        type = "text",
        readOnly,
        variant = "outlined",
        ...inputProps
    } = props;

    const {
        field = {},
    } = inputProps;

    return (
        <div
            style={style}
            className={cx("form-input", className, {
                [`form-input--${variant}`]: true,
                "form-input--disabled": disabled,
                "form-input--error": error,
                "form-input--hasValue": inputProps?.field?.value && String(inputProps?.field?.value).length > 0,
            })}
        >
            {variant === "search" && (
                <svg viewBox="0 0 20 20" fill="none" width="20" height="20" xmlns="http://www.w3.org/2000/svg" className="search-icon">
                    <path
                        d="M16.3 8.75a7.93 7.93 0 01-7.8 8.05A7.93 7.93 0 01.7 8.75 7.93 7.93 0 018.5.7c4.29 0 7.8 3.58 7.8 8.05z"
                        stroke="var(--color-main-secondary-text, #888A89)" strokeWidth="1.4"></path>
                    <path d="M14.5 14.5L19 19" stroke="var(--color-main-secondary-text, #888A89)" strokeWidth="1.4"
                          strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            )}
            <input
                className="form-control"
                type={type}
                disabled={disabled}
                readOnly={readOnly}
                placeholder={!label ? placeholder : ""}
                {...field}
                {...inputProps}
            />
        </div>
    );
};
export { Input };