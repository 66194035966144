import React from "react";
import "./Button.scss";
import cx from "classnames";

export const BTN_PRIMARY = 'primary';
export const BTN_SECONDARY = 'secondary';
export const BTN_ICON = 'icon';
export const BTN_OUTLINED = 'outlined';
export const BTN_LINK = 'link';

export const Button = (props) => {
    const {
        children = "",
        className = "",
        style = {},
        size = "",
        btnType = "default",
        disabled = false,
        loading = false,
        loadingIndicator = <span className="dm dm-mail spin" />,
        type = 'button',
        ...btnProps
    } = props;

    return (
        <button
            style={style}
            disabled={disabled || loading}
            className={cx("btn", {
                [className]: true,
                [`btn--${btnType}`]: true,
                [`btn--${size}`]: size,
                "btn--disabled": disabled,
                "btn--loading": loading,
            })}
            type={type}
            {...btnProps}
        >
            {loading ? loadingIndicator : children}
        </button>
    );
}
