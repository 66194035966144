import React from "react";
import {Link} from 'react-router-dom';
import logo from '../../../assets/images/scrypton-logo-orange.svg';
import {BTN_ICON} from '../../Material/Button/Button';
import {Button} from '../../Material/Button';
import cx from 'classnames';
import "./Header.scss";

export const Header = (props) => {
    const {
        setOpenSidebar,
        showSidebar
    } = props;

    const MENU_LIST = [
        // {
        //     title: 'Home',
        //     url: '/',
        // },
        // {
        //     title: 'Language',
        //     action: () => {
        //     },
        //     selectedLang: 'EN'
        // },
        {
            title: 'Scrypton Invoice',
            url: 'https://invoice.scrypton.io/create-invoice',
        },
        {
            title: 'Privacy',
            url: 'https://scrypton.io/privacy.html',
        },
        {
            title: 'Cookies',
            url: 'https://scrypton.io/cookie.html',
        },
        {
            title: 'Terms',
            url: 'https://scrypton.io/terms.html',
        },
        {
            title: 'About Us',
            url: 'https://scrypton.io/about-us.html',
        },
        // {
        //     title: 'Log Out',
        //     action: () => {
        //     }
        // },
    ];

    return (
        <>
            <header className="header">
                <div className="header__container">
                <Link className="logo-wrapper" onClick={() => window.location.reload(false)}>
                    <img
                        className="logo"
                        width="160"
                        height="44"
                        src={logo}
                        alt="Scrypton"
                    />
                </Link>
                {/*//// VISIBLE ON DESKTOP //////*/}
                <nav>
                    <ul className="menu__list navbar-nav">
                        {MENU_LIST.map((item, index) => {
                            return (
                                <li className="menu__list__item" key={index}>
                                    {
                                        item?.action ? (
                                            <div className="nav-item"
                                                 onClick={item.action}
                                                 role={'link'}
                                                 tabIndex="0"
                                            >
                                                <h4 className="heading-3">
                                                    {item.title}
                                                </h4>
                                            </div>
                                        ) : (
                                            <a
                                                className="nav-item"
                                                href={item.url}
                                                target={item.onSameTab ? "" : "_blank"}
                                            >
                                                <h4 className="heading-3">{item.title}</h4>
                                            </a>
                                        )
                                    }
                                </li>
                            );
                        })}
                    </ul>
                </nav>

                <Button
                    btnType={BTN_ICON}
                    className=" burger-menu navbar-toggler"
                    onClick={() => setOpenSidebar(!showSidebar)}
                >
                    <div className={cx("burger-menu__inner", {
                        active: showSidebar,
                    })}>
                        <span
                            className={cx("burger-menu__top", {
                                active: showSidebar,
                            })}
                        />
                        <span
                            className={cx("burger-menu__bottom", {
                                active: showSidebar,
                            })}
                        />
                    </div>
                </Button>
                </div>
            </header>
        </>
    );
}
