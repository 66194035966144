import {put, takeLatest, cancel, fork} from 'redux-saga/effects';
import {
    GET_TRANSACTION_DETAILS_REQUEST,
    GET_WIDGET_BUY_CURRENCIES_REQUEST,
    GET_WIDGET_BUY_RATE_REQUEST,
    GET_WIDGET_COUNTRIES_REQUEST,
    GET_WIDGET_CURRENCIES_REQUEST,
    GET_WIDGET_CURRENCY_LIMITS_REQUEST, GET_WIDGET_RATES_REQUEST,
    GET_WIDGET_SELL_CURRENCIES_REQUEST,
    GET_WIDGET_SELL_RATE_REQUEST,
    GET_WIDGET_USER_DATA_REQUEST,
    VALIDATE_ADDRESS_REQUEST,
} from './constants';
import {httpService} from '../../services/httpService';
import * as actionCreators from './actions';
import {objToQueryString} from '../../utils/utils';
import {adaptCountriesList, adaptCurrenciesData} from './adapter';

const ENDPOINTS = {
    WIDGET_CURRENCIES: '/widget/currencies',
    WIDGET_COUNTRIES: '/widget/countries',
    WIDGET_CURRENCY_LIMITS: '/widget/currency-limits',
    WIDGET_BUY_RATE: '/widget/buy-rate',
    WIDGET_RATES: '/widget/rates',
    WIDGET_SELL_RATE: '/widget/sell-rate',
    WIDGET_BUY_CURRENCIES: '/widget/buy-currencies',
    WIDGET_SELL_CURRENCIES: '/widget/sell-currencies',
    COUNTRIES_DETAILS: '/widget/countries-details',
    USER_DATA: '/widget/user-data',
    VALIDATE_ADDRESS: '/widget/validate-address',
    GET_TRANSACTION_DETAILS: '/widget/transaction-details',
};

const watchMercuryoWidgetSaga = function* (){
    yield takeLatest(GET_WIDGET_CURRENCIES_REQUEST, getWidgetCurrencies);
    yield takeLatest(GET_WIDGET_COUNTRIES_REQUEST, getWidgetCountries);
    yield takeLatest(GET_WIDGET_CURRENCY_LIMITS_REQUEST, getWidgetCurrencyLimits);
    yield takeLatest(GET_WIDGET_RATES_REQUEST, watchInputValueChange);
    yield takeLatest(GET_WIDGET_BUY_RATE_REQUEST, getWidgetBuyRate);
    yield takeLatest(GET_WIDGET_SELL_RATE_REQUEST, getWidgetSellRate);
    yield takeLatest(GET_WIDGET_BUY_CURRENCIES_REQUEST, getWidgetBuyCurrencies);
    yield takeLatest(GET_WIDGET_SELL_CURRENCIES_REQUEST, getWidgetSellCurrencies);
    yield takeLatest(GET_WIDGET_USER_DATA_REQUEST, getWidgetUserData);
    yield takeLatest(VALIDATE_ADDRESS_REQUEST, validateWalletAddress);
    yield takeLatest(GET_TRANSACTION_DETAILS_REQUEST, getTransactionDetails);
};

const getWidgetCurrencies = function* (action) {
    const response = yield httpService.get(ENDPOINTS.WIDGET_CURRENCIES);
    if(response.success === true) {
        const adaptedData = adaptCurrenciesData(response.payload?.data);
        yield put(actionCreators.getWidgetCurrenciesSuccess(adaptedData));
    } else {
        yield put(actionCreators.getWidgetCurrenciesFailure(response));
    }
}

const getWidgetCountries = function* (action) {
    const response = yield httpService.get(ENDPOINTS.WIDGET_COUNTRIES);
    const detailsResponse = yield httpService.get(ENDPOINTS.COUNTRIES_DETAILS);

    if(response.success === true && detailsResponse.success === true) {
        const adaptedData = adaptCountriesList(response.payload?.data, detailsResponse.payload);
        yield put(actionCreators.getWidgetCountriesSuccess(adaptedData));
    } else {
        yield put(actionCreators.getWidgetCountriesFailure(response));
    }
}

const getWidgetCurrencyLimits = function* (action) {
    const params = action.payload ? objToQueryString(action.payload) : '';
    const response = yield httpService.get(`${ENDPOINTS.WIDGET_CURRENCY_LIMITS}?${params}`);
    if(response.success === true) {
        yield put(actionCreators.getWidgetCurrencyLimitsSuccess(response.payload));
    } else {
        yield put(actionCreators.getWidgetCurrencyLimitsFailure(response));
    }
}

const getWidgetRates = function* (action) {
    const params = action.payload ? objToQueryString(action.payload) : '';
    const response = yield httpService.get(`${ENDPOINTS.WIDGET_RATES}?${params}`);
    if(response.success === true) {
        yield put(actionCreators.getWidgetRatesSuccess(response.payload));
    } else {
        yield put(actionCreators.getWidgetRatesFailure(response));
    }
}

const watchInputValueChange = function* (action) {
    let task;
    if (task) {
        yield cancel(task);
    }
    task = yield fork(getWidgetRates, action);
}

const getWidgetBuyRate = function* (action) {
    const params = action.payload ? objToQueryString(action.payload) : '';
    const response = yield httpService.get(`${ENDPOINTS.WIDGET_BUY_RATE}?${params}`);
    if(response.success === true) {
        yield put(actionCreators.getWidgetBuyRateSuccess(response.payload));
    } else {
        yield put(actionCreators.getWidgetBuyRateFailure(response));
    }
}

const getWidgetSellRate = function* (action) {
    const params = action.payload ? objToQueryString(action.payload) : '';
    const response = yield httpService.get(`${ENDPOINTS.WIDGET_SELL_RATE}?${params}`);
    if(response.success === true) {
        yield put(actionCreators.getWidgetSellRateSuccess(response.payload));
    } else {
        yield put(actionCreators.getWidgetSellRateFailure(response));
    }
}

const getWidgetBuyCurrencies = function* (action) {
    const response = yield httpService.get(ENDPOINTS.WIDGET_BUY_CURRENCIES);
    if(response.success === true) {
        yield put(actionCreators.getWidgetBuyCurrenciesSuccess(response.payload));
    } else {
        yield put(actionCreators.getWidgetBuyCurrenciesFailure(response));
    }
}

const getWidgetSellCurrencies = function* (action) {
    const response = yield httpService.get(ENDPOINTS.WIDGET_SELL_CURRENCIES);
    if(response.success === true) {
        yield put(actionCreators.getWidgetSellCurrenciesSuccess(response.payload));
    } else {
        yield put(actionCreators.getWidgetSellCurrenciesFailure(response));
    }
}

const getWidgetUserData = function* (action) {
    const response = yield httpService.get(ENDPOINTS.USER_DATA);
    if(response.success === true) {
        yield put(actionCreators.getWidgetUserDataSuccess(response.payload));
    } else {
        yield put(actionCreators.getWidgetUserDataFailure(response));
    }
}

const validateWalletAddress = function* (action) {
    const params = action.payload ? objToQueryString(action.payload) : '';
    const response = yield httpService.get(`${ENDPOINTS.VALIDATE_ADDRESS}?${params}`);
    if(response.success === true) {
        yield put(actionCreators.validateWalletAddressSuccess(response.payload));
    } else {
        yield put(actionCreators.validateWalletAddressFailure(response));
    }
}

const getTransactionDetails = function* (action) {
    const params = action.payload ? objToQueryString(action.payload) : '';
    const response = yield httpService.get(`${ENDPOINTS.GET_TRANSACTION_DETAILS}?${params}`);
    if(response.success === true) {
        yield put(actionCreators.getTransactionDetailsSuccess(response.payload));
    } else {
        yield put(actionCreators.getTransactionDetailsFailure(response));
    }
}

export default watchMercuryoWidgetSaga;