import {
    GET_WIDGET_BUY_RATE_FAILURE,
    GET_WIDGET_BUY_RATE_REQUEST,
    GET_WIDGET_BUY_RATE_SUCCESS,
    GET_WIDGET_COUNTRIES_FAILURE,
    GET_WIDGET_COUNTRIES_REQUEST,
    GET_WIDGET_COUNTRIES_SUCCESS,
    GET_WIDGET_CURRENCIES_FAILURE,
    GET_WIDGET_CURRENCIES_REQUEST,
    GET_WIDGET_CURRENCIES_SUCCESS,
    GET_WIDGET_CURRENCY_LIMITS_FAILURE,
    GET_WIDGET_CURRENCY_LIMITS_REQUEST,
    GET_WIDGET_CURRENCY_LIMITS_SUCCESS,
    GET_WIDGET_SELL_RATE_FAILURE,
    GET_WIDGET_SELL_RATE_REQUEST,
    GET_WIDGET_SELL_RATE_SUCCESS,
    GET_WIDGET_BUY_CURRENCIES_REQUEST,
    GET_WIDGET_BUY_CURRENCIES_SUCCESS,
    GET_WIDGET_BUY_CURRENCIES_FAILURE,
    GET_WIDGET_SELL_CURRENCIES_REQUEST,
    GET_WIDGET_SELL_CURRENCIES_SUCCESS,
    GET_WIDGET_SELL_CURRENCIES_FAILURE,
    RESET_MERCURYO_WIDGET_STORE,
    GET_WIDGET_USER_DATA_REQUEST,
    GET_WIDGET_USER_DATA_SUCCESS,
    GET_WIDGET_USER_DATA_FAILURE,
    VALIDATE_ADDRESS_REQUEST,
    VALIDATE_ADDRESS_SUCCESS,
    VALIDATE_ADDRESS_FAILURE,
    GET_WIDGET_RATES_REQUEST,
    GET_WIDGET_RATES_SUCCESS,
    GET_WIDGET_RATES_FAILURE,
    GET_TRANSACTION_DETAILS_REQUEST,
    GET_TRANSACTION_DETAILS_SUCCESS,
    GET_TRANSACTION_DETAILS_FAILURE,
} from './constants';

export const getWidgetCurrenciesRequest = (payload) => {
    return {
        type: GET_WIDGET_CURRENCIES_REQUEST,
        payload,
    };
};

export const getWidgetCurrenciesSuccess = (payload) => {
    return {
        type: GET_WIDGET_CURRENCIES_SUCCESS,
        payload,
    };
};

export const getWidgetCurrenciesFailure = (payload) => {
    return {
        type: GET_WIDGET_CURRENCIES_FAILURE,
        payload,
    };
};

export const getWidgetCountriesRequest = (payload) => {
    return {
        type: GET_WIDGET_COUNTRIES_REQUEST,
        payload,
    };
};

export const getWidgetCountriesSuccess = (payload) => {
    return {
        type: GET_WIDGET_COUNTRIES_SUCCESS,
        payload,
    };
};

export const getWidgetCountriesFailure = (payload) => {
    return {
        type: GET_WIDGET_COUNTRIES_FAILURE,
        payload,
    };
};

export const getWidgetCurrencyLimitsRequest = (payload) => {
    return {
        type: GET_WIDGET_CURRENCY_LIMITS_REQUEST,
        payload,
    };
};

export const getWidgetCurrencyLimitsSuccess = (payload) => {
    return {
        type: GET_WIDGET_CURRENCY_LIMITS_SUCCESS,
        payload,
    };
};

export const getWidgetCurrencyLimitsFailure = (payload) => {
    return {
        type: GET_WIDGET_CURRENCY_LIMITS_FAILURE,
        payload,
    };
};

export const getWidgetRatesRequest = (payload) => {
    return {
        type: GET_WIDGET_RATES_REQUEST,
        payload,
    };
};

export const getWidgetRatesSuccess = (payload) => {
    return {
        type: GET_WIDGET_RATES_SUCCESS,
        payload,
    };
};

export const getWidgetRatesFailure = (payload) => {
    return {
        type: GET_WIDGET_RATES_FAILURE,
        payload,
    };
};

export const getWidgetBuyRateRequest = (payload) => {
    return {
        type: GET_WIDGET_BUY_RATE_REQUEST,
        payload,
    };
};

export const getWidgetBuyRateSuccess = (payload) => {
    return {
        type: GET_WIDGET_BUY_RATE_SUCCESS,
        payload,
    };
};

export const getWidgetBuyRateFailure = (payload) => {
    return {
        type: GET_WIDGET_BUY_RATE_FAILURE,
        payload,
    };
};

export const getWidgetSellRateRequest = (payload) => {
    return {
        type: GET_WIDGET_SELL_RATE_REQUEST,
        payload,
    };
};

export const getWidgetSellRateSuccess = (payload) => {
    return {
        type: GET_WIDGET_SELL_RATE_SUCCESS,
        payload,
    };
};

export const getWidgetSellRateFailure = (payload) => {
    return {
        type: GET_WIDGET_SELL_RATE_FAILURE,
        payload,
    };
};

export const getWidgetBuyCurrenciesRequest = (payload) => {
    return {
        type: GET_WIDGET_BUY_CURRENCIES_REQUEST,
        payload,
    };
};

export const getWidgetBuyCurrenciesSuccess = (payload) => {
    return {
        type: GET_WIDGET_BUY_CURRENCIES_SUCCESS,
        payload,
    };
};

export const getWidgetBuyCurrenciesFailure = (payload) => {
    return {
        type: GET_WIDGET_BUY_CURRENCIES_FAILURE,
        payload,
    };
};

export const getWidgetSellCurrenciesRequest = (payload) => {
    return {
        type: GET_WIDGET_SELL_CURRENCIES_REQUEST,
        payload,
    };
};

export const getWidgetSellCurrenciesSuccess = (payload) => {
    return {
        type: GET_WIDGET_SELL_CURRENCIES_SUCCESS,
        payload,
    };
};

export const getWidgetSellCurrenciesFailure = (payload) => {
    return {
        type: GET_WIDGET_SELL_CURRENCIES_FAILURE,
        payload,
    };
};

export const getWidgetUserDataRequest = (payload) => {
    return {
        type: GET_WIDGET_USER_DATA_REQUEST,
        payload,
    };
};

export const getWidgetUserDataSuccess = (payload) => {
    return {
        type: GET_WIDGET_USER_DATA_SUCCESS,
        payload,
    };
};

export const getWidgetUserDataFailure = (payload) => {
    return {
        type: GET_WIDGET_USER_DATA_FAILURE,
        payload,
    };
};

export const resetMercuryoWidgetStore = (payload) => {
    return {
        type: RESET_MERCURYO_WIDGET_STORE,
        payload,
    }
}

export const validateWalletAddressRequest = (payload) => {
    return {
        type: VALIDATE_ADDRESS_REQUEST,
        payload,
    }
}

export const validateWalletAddressSuccess = (payload) => {
    return {
        type: VALIDATE_ADDRESS_SUCCESS,
        payload,
    }
}

export const validateWalletAddressFailure = (payload) => {
    return {
        type: VALIDATE_ADDRESS_FAILURE,
        payload,
    }
}

export const getTransactionDetailsRequest = (payload) => {
    return {
        type: GET_TRANSACTION_DETAILS_REQUEST,
        payload,
    }
}

export const getTransactionDetailsSuccess = (payload) => {
    return {
        type: GET_TRANSACTION_DETAILS_SUCCESS,
        payload,
    }
}

export const getTransactionDetailsFailure = (payload) => {
    return {
        type: GET_TRANSACTION_DETAILS_FAILURE,
        payload,
    }
}
