import {Input} from '../../../../components/Material/Input';
import {SelectItems} from '../../../../components/Material/SelectItems';
import {Modal} from '../../../../components/Common/Modal';
import React, {useEffect, useMemo, useState} from 'react';
import {ACTION_TYPES, CURRENCY_TYPES} from '../constants';
import {useSelector} from 'react-redux';

export const CurrenciesModal = (props) => {
    const {
        cryptoOptions,
        fiatOptions,
        currencyModalOpen,
        closeCurrencyModal,
        handleCryptoSelect,
        handleFiatSelect,
        currencyModalType,
        actionType,
    } = props;

    const buyCurrencies = useSelector(state => state.mercuryoWidgetReducer.buyCurrencies);
    const sellCurrencies = useSelector(state => state.mercuryoWidgetReducer.sellCurrencies);

    const isFiat = useMemo(() => {
        return currencyModalType === CURRENCY_TYPES.FIAT;
    }, [currencyModalType]);

    const optionList = useMemo(() => {
        let list = isFiat ? fiatOptions : cryptoOptions;
        let availableCurrencies = actionType === ACTION_TYPES.BUY ? buyCurrencies : sellCurrencies;

        list = list.filter(item => availableCurrencies?.some(curr => curr === item.name));

        return list;
    }, [
        isFiat,
        fiatOptions,
        cryptoOptions,
        actionType,
        buyCurrencies,
        sellCurrencies
    ]);

    const [searchValue, setSearchValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(null);

    useEffect(() => {
        if(searchValue) {
            const filteredOptions = optionList.filter(item => {
                return (
                    item?.fullName?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                    item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                    item?.network?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                    item?.networkLabel?.toLowerCase()?.includes(searchValue?.toLowerCase())
                );
            })
            setFilteredOptions(filteredOptions);
        } else {
            setFilteredOptions(null);
        }
    }, [searchValue])

    const handleSearch = (event) => {
        setSearchValue(event.target?.value?.trim());
    }

    const onClose = () => {
        setFilteredOptions(null);
        closeCurrencyModal();
    }

    const onSelect = (item) => {
        setFilteredOptions(null);

        if(isFiat) {
            handleFiatSelect(item);
        } else {
            handleCryptoSelect(item);
        }
    }

    return (
        <Modal
            isOpen={currencyModalOpen}
            closeHandler={onClose}
            title="Select Currency"
            className="currency-modal"
        >
            <div className="search-wrapper">
                <h2>Select currency</h2>
                <Input
                    placeholder={isFiat ? 'Fiat' : 'Crypto'}
                    variant="search"
                    onChange={handleSearch}
                />
            </div>
            <div className="list">
                <p className="fiat">{isFiat ? 'Fiat' : 'Crypto'}</p>
                <SelectItems
                    type="currency"
                    selectList={filteredOptions || optionList}
                    handleOptionSelect={onSelect}
                />
            </div>
        </Modal>
    );
}
