export const adaptCountriesList = (countries, details) => {
    const adaptedList = [];

    for(let i = 0; i < countries.length; i++) {
        let currCountry = details.find(item => item?.cca3?.toLowerCase() === countries[i]);
        if(currCountry) {
            adaptedList.push({
                ...currCountry,
                name: currCountry.name?.common,
                img: currCountry.flags?.svg,
                imgAlt: currCountry.flags?.alt,
            });
        }
    }
    return adaptedList;
};

export const adaptCurrenciesData = (currenciesData) => {
    const adaptedData = {
        crypto: [],
        fiat: [],
        displayOptions: {},
    }

    const {
        config,
        crypto,
        fiat,
    } = currenciesData;

    const {
        base = {},
        crypto_currencies = [],
        default_networks = {},
        display_options = {},
        has_withdrawal_fee = {},
        icons = {},
        networks = {},
    } = config || {};

    for(let i = 0; i < crypto_currencies.length; i++) {
        const itemDetails = crypto_currencies[i];
        const currItemName = itemDetails.currency;
        let networkInfo = {};

        if(itemDetails.show_network_icon) {
            networkInfo = {
                icon: networks[itemDetails?.network]?.icons?.svg,
                networkLabel: itemDetails?.network_label,
            }
        }
        networkInfo.networkName = itemDetails?.network;

        adaptedData.crypto.push({
            name: currItemName,
            img: icons[currItemName]?.svg || icons[currItemName]?.png,
            fullName: display_options[currItemName]?.fullname,
            ...networkInfo,
        });
    }

    for(let i = 0; i < fiat.length; i++) {
        const currItemName = fiat[i];
        adaptedData.fiat.push({
            name: currItemName,
            img: config.icons[currItemName].svg || config.icons[currItemName].png,
            fullName: config.display_options[currItemName]?.fullname,
        });
    }

    adaptedData.displayOptions = display_options;

    return adaptedData;
};