import React from 'react';
import cx from 'classnames';

export const SelectItem = (props) => {
    const {
        selectedItem,
        type = 'country',
        handleOptionSelect,
    } = props;
    const isImageFormat = selectedItem?.img?.startsWith('/') || selectedItem?.img.startsWith('http');

    return (
        <li className="select-list__item" onClick={() => handleOptionSelect(selectedItem)}>
            <div className={cx('thumbnail',
            {
                'symbol-bg' : !isImageFormat
            })}>
                {isImageFormat || type === 'currency' ?
                    <img width="32" height="32" src={selectedItem.img} alt={selectedItem.imgAlt || selectedItem.name}/>
                : <h4 className="heading-3">{selectedItem?.img}</h4>}
            </div>
            <div className="content">
                {selectedItem.name ? (
                    <>
                        <h4 className="regular-text">
                            {selectedItem.name}
                            {
                                type === 'currency' && selectedItem.icon && selectedItem.networkName && (
                                    <div className="type">
                                        <div className="type-item"><img width="16" height="16"
                                                                        src={selectedItem.icon} className="Lu2LD"
                                                                        alt="ARBITRUM" loading="lazy"/></div>
                                        <div className="type-name">{selectedItem.networkLabel}</div>
                                    </div>
                                )
                            }
                        </h4>

                    </>) : null}
                {selectedItem.fullName && type === 'currency' ? <span className="body-text">{selectedItem.fullName}</span> : null}
            </div>
        </li>
    );
};
