import React, {useEffect, useState} from 'react';
import {WidgetCustomContent} from './WidgetCustomContent';
import {ACTION_TYPES} from '../constants';
import {sha512} from 'js-sha512';
import {MercuryoWidgetLoading} from './MercuryoWidgetLoading';
import CryptoJS from 'crypto-js';
import {queryStringToObject} from '../../../utils/utils';

const WIDGET_ID = process.env.REACT_APP_MERCURYO_WIDGET_ID;
const WIDGET_SECRET = process.env.REACT_APP_MERCURYO_WIDGET_SECRET;

export const MercuryoWidget = (props) => {
    const {
        widgetStarted,
        setWidgetStarted,
        widgetConfigReady,
        setWidgetConfigIsReady
    } = props;

    const [actionType, setActionType] = useState(ACTION_TYPES.BUY);

    const [selectedCrypto, setSelectedCrypto] = useState(null);
    const [selectedFiat, setSelectedFiat] = useState(null);
    const [fiatAmount, setFiatAmount] = useState(0);
    const [cryptoAmount, setCryptoAmount] = useState(0);
    const [address, setAddress] = useState('');

    const [widgetLoaded, setWidgetLoaded] = useState(false);
    const [skipCustomSteps, setSkipCustomSteps] = useState(false);
    const [isIncorrectUrl, setIsIncorrectUrl] = useState(false);

    useEffect(() => {
        if(!widgetLoaded) {
            const search = new URL(window.location.href).search;
            // const search = window.location.search;
            if(search) {
                setSkipCustomSteps(true);

                const paramsStartIndex = search.indexOf('=') + 1;

                if(paramsStartIndex === 0) {
                    setIsIncorrectUrl(true);
                    return;
                }

                const encodedParams = search.substring(paramsStartIndex);
                let decryptedParams;

                try {
                    decryptedParams = CryptoJS.AES.decrypt(encodedParams, process.env.REACT_APP_REDIRECT_KEY)?.toString(CryptoJS.enc.Utf8);
                } catch (error) {
                    console.error('Error during decryption:', error);
                    setIsIncorrectUrl(true);
                    return;
                }

                const configParams = queryStringToObject(decryptedParams);

                if((!configParams.amount && !configParams.fiatAmount) || !configParams.address) {
                    setIsIncorrectUrl(true);
                    return;
                }

                autoStartWidget(configParams);
            }
        }
    }, []);

    useEffect(() => {
        if(!widgetStarted && widgetConfigReady) {
            startMercuryoWidget();

            setWidgetStarted(true);
        }
    }, [widgetStarted, widgetConfigReady]);

    useEffect(() => {
        if(widgetStarted && widgetConfigReady) {
            const widgetLoadTimerId = setTimeout(() => timeoutCallback(widgetLoaded, setWidgetLoaded), 0);

            return () => {
                clearTimeout(widgetLoadTimerId);
            };
        }
    }, [widgetStarted, widgetConfigReady]);

    const timeoutCallback = (widgetLoaded, setWidgetLoaded) => {
        if(!widgetLoaded) {
            const mercuryoIframe = document.querySelector('iframe');

            if(mercuryoIframe) {
                mercuryoIframe.style.borderRadius = '24px';
                mercuryoIframe.style.padding = '10px 10px';

                mercuryoIframe.onload = function () {
                    setWidgetLoaded(true);
                }
            }
        }
    }

    const autoStartWidget = (configParams) => {
        const widgetHostElement = document.getElementById('mercuryo-widget');

        const normalizedConfigParams = { ...configParams };

        if(normalizedConfigParams?.orderId) {
            delete normalizedConfigParams.orderId;
        }

        const widgetConfig = {
            widgetId: WIDGET_ID,
            host: widgetHostElement,
            theme: 'scrypton',
            hasSellEnabled: false,
            type: 'buy',
            signature: sha512(`${configParams?.address}${WIDGET_SECRET}`),
            merchantTransactionId: configParams?.orderId || '',
            ...normalizedConfigParams,
        };

        window.mercuryoWidget.run(widgetConfig);
        timeoutCallback(widgetLoaded, setWidgetLoaded);
    };

    const startMercuryoWidget = () => {
        const widgetHostElement = document.getElementById('mercuryo-widget');

        let additionalProps = {};

        if(actionType === ACTION_TYPES.BUY) {
            additionalProps = {
                address,
                signature: sha512(`${address}${WIDGET_SECRET}`),
            }
        } else {
            additionalProps = {
                type: actionType,
            }
        }

        const widgetConfig = {
            widgetId: WIDGET_ID,
            host: widgetHostElement,
            theme: 'scrypton',
            hasSellEnabled: true,
            fiatAmount: +fiatAmount,
            currency: selectedCrypto?.name,
            fiatCurrency: selectedFiat?.name,
            network: selectedCrypto?.networkName,
            ...additionalProps,
        };

        window.mercuryoWidget.run(widgetConfig);
    }

    return (
        <>
            {
                !widgetLoaded && !skipCustomSteps && (
                    (!widgetStarted && !widgetConfigReady) ? (
                        <WidgetCustomContent
                            address={address}
                            setAddress={setAddress}
                            actionType={actionType}
                            setActionType={setActionType}
                            selectedCrypto={selectedCrypto}
                            setSelectedCrypto={setSelectedCrypto}
                            selectedFiat={selectedFiat}
                            setSelectedFiat={setSelectedFiat}
                            setWidgetConfigIsReady={setWidgetConfigIsReady}
                            fiatAmount={fiatAmount}
                            cryptoAmount={cryptoAmount}
                            setFiatAmount={setFiatAmount}
                            setCryptoAmount={setCryptoAmount}
                        />
                    ) : (
                        <MercuryoWidgetLoading />
                    )
                )
            }
            {
                skipCustomSteps && !widgetLoaded && !isIncorrectUrl && (
                    <MercuryoWidgetLoading />
                )
            }
            {
                isIncorrectUrl && (
                    <div className='incorrect-url'>
                        <p>Incorrect URL.</p>
                        <p>Check its validity and try again.</p>
                    </div>
                )
            }
        </>

    );
};
