export const GET_WIDGET_CURRENCIES_REQUEST = 'GET_WIDGET_CURRENCIES_REQUEST';
export const GET_WIDGET_CURRENCIES_SUCCESS = 'GET_WIDGET_CURRENCIES_SUCCESS';
export const GET_WIDGET_CURRENCIES_FAILURE = 'GET_WIDGET_CURRENCIES_FAILURE';

export const GET_WIDGET_COUNTRIES_REQUEST = 'GET_WIDGET_COUNTRIES_REQUEST';
export const GET_WIDGET_COUNTRIES_SUCCESS = 'GET_WIDGET_COUNTRIES_SUCCESS';
export const GET_WIDGET_COUNTRIES_FAILURE = 'GET_WIDGET_COUNTRIES_FAILURE';

export const GET_WIDGET_CURRENCY_LIMITS_REQUEST = 'GET_WIDGET_CURRENCY_LIMITS_REQUEST';
export const GET_WIDGET_CURRENCY_LIMITS_SUCCESS = 'GET_WIDGET_CURRENCY_LIMITS_SUCCESS';
export const GET_WIDGET_CURRENCY_LIMITS_FAILURE = 'GET_WIDGET_CURRENCY_LIMITS_FAILURE';

export const GET_WIDGET_RATES_REQUEST = 'GET_WIDGET_RATES_REQUEST';
export const GET_WIDGET_RATES_SUCCESS = 'GET_WIDGET_RATES_SUCCESS';
export const GET_WIDGET_RATES_FAILURE = 'GET_WIDGET_RATES_FAILURE';

export const GET_WIDGET_BUY_RATE_REQUEST = 'GET_WIDGET_BUY_RATE_REQUEST';
export const GET_WIDGET_BUY_RATE_SUCCESS = 'GET_WIDGET_BUY_RATE_SUCCESS';
export const GET_WIDGET_BUY_RATE_FAILURE = 'GET_WIDGET_BUY_RATE_FAILURE';

export const GET_WIDGET_SELL_RATE_REQUEST = 'GET_WIDGET_SELL_RATE_REQUEST';
export const GET_WIDGET_SELL_RATE_SUCCESS = 'GET_WIDGET_SELL_RATE_SUCCESS';
export const GET_WIDGET_SELL_RATE_FAILURE = 'GET_WIDGET_SELL_RATE_FAILURE';

export const GET_WIDGET_BUY_CURRENCIES_REQUEST = 'GET_WIDGET_BUY_CURRENCIES_REQUEST';
export const GET_WIDGET_BUY_CURRENCIES_SUCCESS = 'GET_WIDGET_BUY_CURRENCIES_SUCCESS';
export const GET_WIDGET_BUY_CURRENCIES_FAILURE = 'GET_WIDGET_BUY_CURRENCIES_FAILURE';

export const GET_WIDGET_SELL_CURRENCIES_REQUEST = 'GET_WIDGET_SELL_CURRENCIES_REQUEST';
export const GET_WIDGET_SELL_CURRENCIES_SUCCESS = 'GET_WIDGET_SELL_CURRENCIES_SUCCESS';
export const GET_WIDGET_SELL_CURRENCIES_FAILURE = 'GET_WIDGET_SELL_CURRENCIES_FAILURE';

export const VALIDATE_ADDRESS_REQUEST = 'VALIDATE_ADDRESS_REQUEST';
export const VALIDATE_ADDRESS_SUCCESS = 'VALIDATE_ADDRESS_SUCCESS';
export const VALIDATE_ADDRESS_FAILURE = 'VALIDATE_ADDRESS_FAILURE';

export const GET_TRANSACTION_DETAILS_REQUEST = 'GET_TRANSACTION_DETAILS_REQUEST';
export const GET_TRANSACTION_DETAILS_SUCCESS = 'GET_TRANSACTION_DETAILS_SUCCESS';
export const GET_TRANSACTION_DETAILS_FAILURE = 'GET_TRANSACTION_DETAILS_FAILURE';

export const GET_WIDGET_USER_DATA_REQUEST = 'GET_WIDGET_USER_DATA_REQUEST';
export const GET_WIDGET_USER_DATA_SUCCESS = 'GET_WIDGET_USER_DATA_SUCCESS';
export const GET_WIDGET_USER_DATA_FAILURE = 'GET_WIDGET_USER_DATA_FAILURE';

export const RESET_MERCURYO_WIDGET_STORE = 'RESET_MERCURYO_WIDGET_STORE';

export const ACTION_TYPES = {
    BUY: 'buy',
    SELL: 'sell',
}

export const CUSTOM_STEPS = {
    buy: 'buy_crypto',
    wallet: 'wallet_address',
}

export const DEFAULT_CRYPTO = 'BTC';
export const DEFAULT_FIAT = 'USD';
export const DEFAULT_NETWORK = 'BITCOIN';

export const CURRENCY_TYPES = {
    FIAT: 'fiat',
    CRYPTO: 'crypto',
}

export const CURRENCY_SYMBOLS = {
    USD: '\u0024',
    EUR: '\u20AC',
    GBP: '\u00A3',
}
