import React, {useEffect} from 'react';

const useOutsideClick = (ref, cb, capturing = false, eventName = 'mousedown') => {
    /**
     * Outside click check hook.
     *
     * @param  {ref} ref - Parent elem ref.
     * @param  {function} cb - Call back function.
     * @return
     */

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            cb(event);
        }
    };

    useEffect(() => {
        document.addEventListener(eventName, handleClickOutside, capturing);
        return () => {
            document.removeEventListener(eventName, handleClickOutside, capturing);
        };
    }, [handleClickOutside]);
};

export {useOutsideClick};