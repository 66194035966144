import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../../assets/images/scrypton-logo.svg';
import {Button} from '../../Material/Button';
import {BTN_ICON} from '../../Material/Button/Button';
import cx from 'classnames';
import './Sidebar.scss';

export const Sidebar = (props) => {
    const {
        active,
        showSidebar,
        setOpenSidebar
    } = props;

    const SIDEBAR_LIST = [
        // {
        //     title: 'Home',
        //     url: '/',
        // },
        // {
        //     title: 'Language',
        //     action: () => {
        //     },
        //     selectedLang: 'EN'
        // },
        {
            title: 'Scrypton Invoice',
            url: 'https://invoice.scrypton.io/create-invoice',
        },
        {
            title: 'Privacy',
            url: 'https://scrypton.io/privacy.html',
        },
        {
            title: 'Cookies',
            url: 'https://scrypton.io/cookie.html',
        },
        {
            title: 'Terms',
            url: 'https://scrypton.io/terms.html',
        },
        {
            title: 'About Us',
            url: 'https://scrypton.io/about-us.html',
        },
        // {
        //     title: 'Log Out',
        //     action: () => {
        //     }
        // },
    ];

    return (
        <>
            <div className={cx('sidebar',
                {
                    'opened': active
                })}>
                <div className="sidebar__content">
                    <Link onClick={() => window.location.reload(false)} style={{display: 'flex'}}>
                        <img
                            className="logo"
                            width="160"
                            height="44"
                            src={logo}
                            alt="Scrypton"
                        />
                    </Link>
                    <Button
                        onClick={() => setOpenSidebar(!showSidebar)}
                        btnType={BTN_ICON}
                        className="burger-menu navbar-toggler pointer"
                    >
                            <div className={cx("burger-menu__inner", {
                                active: showSidebar,
                            })}>
                            <span
                                className={cx("burger-menu__top", {
                                    active: showSidebar,
                                })}
                            />
                                <span
                                    className={cx("burger-menu__bottom", {
                                        active: showSidebar,
                                    })}
                                />
                            </div>
                        </Button>
                </div>
                <div className="content-body">
                    {/*<p className="description body-text">
                        Verify your identity. To make
                        the transaction possible we have
                        to check your selfie and ID.
                    </p>*/}
                    <nav>
                        <ul className="sidebar__list">
                            {SIDEBAR_LIST.map((item, index) => {
                                return (
                                    <li className="sidebar__list__item" key={index}>
                                        {
                                            item?.action ? (
                                                <div className="nav-item"
                                                     onClick={item.action}
                                                     role={'link'}
                                                     tabIndex="0"
                                                >
                                                    <h4 className="heading-2">
                                                        {item.title}
                                                    </h4>
                                                    {/*<h4 className="heading-3">*/}
                                                    {/*    {item.selectedLang ? item.selectedLang : null}*/}
                                                    {/*</h4>*/}
                                                </div>
                                            ) : (
                                                <a
                                                    className="nav-item"
                                                    href={item.url}
                                                    target={item.onSameTab ? "" : "_blank"}
                                                >
                                                    <h4 className="heading-2">{item.title}</h4>
                                                </a>
                                            )
                                        }
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}
