import React from 'react';

export const MercuryoWidgetLoading = (props) => {

    return (
        <div className="loading-wrapper">
            <div className="loading-wrapper-item"/>
            <div className="loading-wrapper-item"/>
            <div className="loading-wrapper-item"/>
        </div>
    );
};