import React, {useEffect, useState} from 'react';
import {CryptoBuyForm} from './CryptoBuyForm';
import {WalletAddressForm} from './WalletAddressForm';
import * as actionCreators from '../actions';
import {useDispatch, useSelector} from 'react-redux';
import {CustomWidgetBottom} from './CustomWidgetBottom';
import {usePrevious} from '../../../../Hooks/usePrevious';
import _isEmpty from 'lodash/isEmpty';
import {CountriesModal} from './CountriesModal';
import {CustomWidgetHeader} from './CustomWidgetHeader';
import {
    ACTION_TYPES,
    CUSTOM_STEPS,
    DEFAULT_CRYPTO,
    DEFAULT_FIAT,
    DEFAULT_NETWORK,
} from '../constants';
import '../Styles/FormStyles.scss';
import '../Styles/MainContent.scss';
import {MercuryoWidgetLoading} from './MercuryoWidgetLoading';

export const WidgetCustomContent = (props) => {
    const {
        address,
        setAddress,
        actionType,
        setActionType,
        selectedCrypto,
        setSelectedCrypto,
        selectedFiat,
        setSelectedFiat,
        setWidgetConfigIsReady,
        fiatAmount,
        cryptoAmount,
        setFiatAmount,
        setCryptoAmount,
    } = props;

    const dispatch = useDispatch();

    const getUserData = () => dispatch(actionCreators.getWidgetUserDataRequest());
    const getCountries = () => dispatch(actionCreators.getWidgetCountriesRequest());
    const getCurrencies = () => dispatch(actionCreators.getWidgetCurrenciesRequest());
    const getCurrencyLimits = (data) => dispatch(actionCreators.getWidgetCurrencyLimitsRequest(data));
    const getWidgetRates = (data) => dispatch(actionCreators.getWidgetRatesRequest(data));
    const getBuyRate = (data) => dispatch(actionCreators.getWidgetBuyRateRequest(data));
    const getBuyCurrencies = () => dispatch(actionCreators.getWidgetBuyCurrenciesRequest());
    const resetWidgetStore = () => dispatch(actionCreators.resetMercuryoWidgetStore());
    const checkAddressValidity = (data) => dispatch(actionCreators.validateWalletAddressRequest(data))
    const getSellRate = (data) => dispatch(actionCreators.getWidgetSellRateRequest(data));
    const getSellCurrencies = () => dispatch(actionCreators.getWidgetSellCurrenciesRequest());
    const getTransactionDetails = (data) => dispatch(actionCreators.getTransactionDetailsRequest(data));

    const cardCurrencies = useSelector(state => state.mercuryoWidgetReducer.currencies);
    const getCurrenciesListLoading = useSelector(state => state.mercuryoWidgetReducer.getCurrenciesListLoading);
    const buyRate = useSelector(state => state.mercuryoWidgetReducer.buyRate);
    const getBuyRateLoading = useSelector(state => state.mercuryoWidgetReducer.getBuyRateLoading);
    const sellRate = useSelector(state => state.mercuryoWidgetReducer.sellRate);
    const isAddressValid = useSelector(state => state.mercuryoWidgetReducer.isAddressValid);
    const widgetRates = useSelector(state => state.mercuryoWidgetReducer.rates);

    const prevGetCurrenciesListLoading = usePrevious(getCurrenciesListLoading);
    const prevGetBuyRateLoading = usePrevious(getBuyRateLoading);
    const prevBuyRate = usePrevious(buyRate);

    const [currentStep, setCurrentStep] = useState(CUSTOM_STEPS.buy);
    const [countryModalOpen, setCountryModalOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [initialAmounts, setInitialAmounts] = useState({fiat: null, crypto: null});

    useEffect(() => {
        // const orderId = 'some_unique_id_5';
        // getTransactionDetails({
        //     order_id: orderId,
        // });

        getUserData();
        getCountries();
        getWidgetRates();
        handleGetBuyRate({from: DEFAULT_FIAT, to: DEFAULT_CRYPTO, network: DEFAULT_NETWORK});
        getCurrencies();
        getBuyCurrencies();
        getSellCurrencies();
        handleGetCurrencyLimits({from: DEFAULT_FIAT, to: DEFAULT_CRYPTO, network: DEFAULT_NETWORK});

        return () => {
            resetWidgetStore();
        };
    }, []);

    useEffect(() => {
        if(prevGetCurrenciesListLoading && !getCurrenciesListLoading && !_isEmpty(cardCurrencies)) {
            const crypto = cardCurrencies.crypto.find(item => item.name === DEFAULT_CRYPTO);
            const fiat = cardCurrencies.fiat.find(item => item.name === DEFAULT_FIAT);
            setSelectedCrypto(crypto);
            setSelectedFiat(fiat);
        }
    }, [prevGetCurrenciesListLoading, getCurrenciesListLoading, cardCurrencies]);

    useEffect(() => {
        if(prevGetBuyRateLoading && !getBuyRateLoading && !_isEmpty(buyRate) && _isEmpty(prevBuyRate)) {
            setInitialAmounts({
                fiat: buyRate?.fiat_amount,
                crypto: buyRate?.amount,
            });
        }
    }, [prevGetBuyRateLoading, getBuyRateLoading, buyRate, prevBuyRate]);

    useEffect(() => {
        if(initialAmounts.fiat) {
            setFiatAmount(initialAmounts.fiat);
        }
        if(initialAmounts.crypto) {
            setCryptoAmount(initialAmounts.crypto);
        }
    }, [initialAmounts]);

    const handleGetBuyRate = ({from, to, network, amount, isTotal = true}) => {
        getBuyRate({
            from,
            to,
            type: 'buy',
            amount: amount || fiatAmount || '300.00',
            network,
            is_total: isTotal,
        });
    };

    const handleGetSellRate = ({from, to, network, amount, isTotal = true}) => {
        getSellRate({
            from,
            to,
            type: 'sell',
            amount: amount || cryptoAmount,
            network,
            is_total: isTotal,
        });
    }

    const handleGetCurrencyLimits = ({from, to, is_total = true}) => {
        getCurrencyLimits({
            from,
            to,
            is_total,
            type: actionType,
        });
    };

    const onBuyFormSubmit = () => {
        if(actionType === ACTION_TYPES.SELL) {
            setWidgetConfigIsReady(true);
        } else {
            setCurrentStep(CUSTOM_STEPS.wallet);
        }
    }

    const onWalletFormSubmit = () => {
        setWidgetConfigIsReady(true);
    }

    const customSteps = {
        buy_crypto: <CryptoBuyForm
            cardCurrencies={cardCurrencies}
            selectedCrypto={selectedCrypto}
            selectedFiat={selectedFiat}
            fiatAmount={fiatAmount}
            cryptoAmount={cryptoAmount}
            actionType={actionType}
            setSelectedCrypto={setSelectedCrypto}
            setSelectedFiat={setSelectedFiat}
            handleSubmit={onBuyFormSubmit}
            buyRate={buyRate}
            sellRate={sellRate}
            handleGetBuyRate={handleGetBuyRate}
            handleGetSellRate={handleGetSellRate}
            setFiatAmount={setFiatAmount}
            setCryptoAmount={setCryptoAmount}
            handleGetCurrencyLimits={handleGetCurrencyLimits}
            widgetRates={widgetRates}
            initialAmounts={initialAmounts}
        />,
        wallet_address: <WalletAddressForm
            address={address}
            selectedCrypto={selectedCrypto}
            selectedFiat={selectedFiat}
            fiatAmount={fiatAmount}
            setAddress={setAddress}
            checkAddressValidity={checkAddressValidity}
            handleSubmit={onWalletFormSubmit}
            actionType={actionType}
            initialValues={{address}}
            handleGetBuyRate={handleGetBuyRate}
            buyRate={buyRate}
            sellRate={sellRate}
            handleGetSellRate={handleGetSellRate}
        />,
    };

    const isBuyCryptoStep = currentStep === CUSTOM_STEPS.buy;

    return (
        selectedCrypto && selectedFiat ? (
            <>
                <CustomWidgetHeader
                    actionType={actionType}
                    setActionType={setActionType}
                    selectedCrypto={selectedCrypto}
                    selectedCountry={selectedCountry}
                    setCountryModalOpen={setCountryModalOpen}
                    isAddressValid={isAddressValid}
                    setAddress={setAddress}
                    setCurrentStep={setCurrentStep}
                    isBuyCryptoStep={isBuyCryptoStep}
                />

                <>
                    {customSteps[currentStep]}
                </>

                {
                    isBuyCryptoStep && (
                        <CustomWidgetBottom />
                    )
                }

                <CountriesModal
                    countryModalOpen={countryModalOpen}
                    setCountryModalOpen={setCountryModalOpen}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                />
            </>
        ) : (
            <MercuryoWidgetLoading />
        )
    );
};
