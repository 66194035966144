import React from 'react';
import arrowBack from '../../../assets/images/arrow-down-light.svg';
import close from '../../../assets/images/close.svg';
import {Button} from '../../Material/Button';
import {BTN_ICON} from '../../Material/Button/Button';
import cx from 'classnames';
import './Modal.scss';

export const Modal = (props) => {
    const {
        title = 'Modal title',
        children,
        closeHandler,
        isOpen,
        hasCloseIcon,
        className
    } = props;

    return (
        <>{
            isOpen ? (
                <div className={cx(
                    "modal",
                    className,
                    {
                        "animate-top" : isOpen
                    })}
                     onClick={(e) => {
                         // e.stopPropagation();
                         closeHandler();
                     }}
                >
                    <div className="modal__content" onClick={e => e.stopPropagation()}>
                        {/*<div className="modal__content__heading">*/}
                            {/*{*/}
                            {/*    !hasCloseIcon ? (*/}
                            {/*        <img onClick={closeHandler} className=" transformed" width="16" height="29" src={arrowBack} alt="Back"/>*/}
                            {/*    ) : (*/}
                            {/*        <Button*/}
                            {/*            btnType={BTN_ICON}*/}
                            {/*            className="outlined-btn modal-action-btn"*/}
                            {/*        >*/}
                            {/*            <img onClick={closeHandler} className=" " width="24" height="24" src={close} alt="Close"/>*/}
                            {/*        </Button>*/}
                            {/*    )*/}
                            {/*}*/}
                            <Button
                                btnType={BTN_ICON}
                                type='button'
                                className="transparent"
                                onClick={closeHandler}
                            >
                                <svg width="19" height="19" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                                     className="Modal__close">
                                    <path d="M1.5 13.64L13.64 1.5l.86.86L2.36 14.5l-.86-.86z"
                                          fill="var(--color-main-text, #232323)"></path>
                                    <path d="M2.36 1.5L14.5 13.64l-.86.86L1.5 2.36l.86-.86z"
                                          fill="var(--color-main-text, #232323)"></path>
                                </svg>
                            </Button>
                            {/*<h3 className="regular-text">{title}</h3>*/}
                        {/*</div>*/}
                        <div className="modal__content__body">
                            {children}
                        </div>
                    </div>
                </div>
            ) : null
        }
        </>
    );
};
