import React from 'react';

export const RatesTimer = (props) => {
    const {
        timer,
        getBuyRateLoading,
    } = props;

    return (
        <div className="accordion__timer">
            All fees included
            {
                timer !== 0 && !getBuyRateLoading ? (
                    <>
                        <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" width="11" height="11" className="timer-svg">
                            <circle cx="5.5" cy="5.5" r="5" stroke="var(--color-main-secondary-text, #9E9E9E)"></circle>
                            <path d="M5.13 3.12v2.75h2.75" stroke="var(--color-main-secondary-text, #9E9E9E)" strokeLinecap="round"></path>
                        </svg>
                        <> Update in <span className="timer">{timer}</span> sec </>
                    </>
                ) : (
                    <>
                        <div className="timer-loading-container">
                            <svg className="timer-loading-svg" viewBox="25 25 50 50">
                                <circle className="circle" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="var(--color-main-secondary-text, #9E9E9E)"></circle>
                            </svg>
                        </div>
                        Updating rates
                    </>
                )
            }
        </div>
    );
};