import _eq from 'lodash/eq';

function subscribeBySelector(selector, cb, options = {}) {
    const {
        firstCall = true,
    } = options;
    let prevValue = selector(this.getState());
    if (firstCall) {
        cb(prevValue, prevValue);
    }
    return this.subscribe(() => {
        const value = selector(this.getState());
        if (!_eq(value, prevValue)) {
            const _prev = prevValue;
            prevValue = value;
            cb(value, _prev);
        }
    });
}

export {subscribeBySelector};