import React from 'react';
import {SelectItem} from './SelectItem';
import './SelectItems.scss';

export const SelectItems = (props) => {
    const {
        selectList,
        type,
        handleOptionSelect,
    } = props;

    return (
        <ul className="select-list">
            {
                selectList.map((item, index) => {
                    return (
                        <SelectItem
                            type={type}
                            key={index}
                            selectedItem={item}
                            handleOptionSelect={handleOptionSelect}
                        />
                    );
                })
            }
        </ul>
    );
};
