import createSagaMiddleware from 'redux-saga';
import {subscribeBySelector} from './subscribeBySelector';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import {configureStore} from '@reduxjs/toolkit';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
        reducer: rootReducer,
        middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

store.subscribeBySelector = subscribeBySelector.bind(store);

export {store};