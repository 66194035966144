import {Input} from '../../../../components/Material/Input';
import {SelectItems} from '../../../../components/Material/SelectItems';
import {Modal} from '../../../../components/Common/Modal';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {usePrevious} from '../../../../Hooks/usePrevious';

export const CountriesModal = (props) => {
    const {
        countryModalOpen,
        setCountryModalOpen,
        selectedCountry,
        setSelectedCountry,
    } = props;

    const userData = useSelector(state => state.mercuryoWidgetReducer.userData);
    const getUserDataSuccess = useSelector(state => state.mercuryoWidgetReducer.getUserDataSuccess);
    const getUserDataFailure = useSelector(state => state.mercuryoWidgetReducer.getUserDataFailure);
    const cardCountriesList = useSelector(state => state.mercuryoWidgetReducer.countriesList);
    const getCountriesListLoading = useSelector(state => state.mercuryoWidgetReducer.getCountriesListLoading);

    const prevGetCountriesListLoading = usePrevious(getCountriesListLoading);

    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if(prevGetCountriesListLoading && !getCountriesListLoading && cardCountriesList.length
            && !selectedCountry && (getUserDataSuccess || getUserDataFailure)) {
            let userCountry;

            if(userData?.country?.code) {
                userCountry = cardCountriesList.find(item => item.cca2.toLowerCase() === userData.country.code)
            }

            if(!userCountry) {
                userCountry = cardCountriesList.find(item => item.cca3 === 'USA')
            }

            setSelectedCountry(userCountry);
        }
    }, [cardCountriesList, getCountriesListLoading, prevGetCountriesListLoading]);


    useEffect(() => {
        if(searchValue) {
            const filteredOptions = cardCountriesList.filter(item => {
                return (
                    item?.cca3?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                    item?.cca2?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                    item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
                );
            })
            setOptions(filteredOptions);
        } else {
            setOptions(cardCountriesList);
        }
    }, [searchValue])

    useEffect(() => {
        if(cardCountriesList.length && !options.length) {
            setOptions(cardCountriesList);
        }
    }, [cardCountriesList]);

    const handleSearch = (event) => {
        setSearchValue(event.target?.value?.trim());
    }

    const onClose = () => {
        closeCountryModal()
        setOptions(cardCountriesList);
    }

    const onSelect = (item) => {
        setOptions(cardCountriesList);
        handleCountrySelect(item);
    }

    const handleCountrySelect = (item) => {
        setSelectedCountry(item);
        closeCountryModal();
    }

    const closeCountryModal = () => {
        setCountryModalOpen(false);
    };

    return (
        <Modal
            isOpen={countryModalOpen}
            closeHandler={onClose}
            title="Select Country"
            className="country-modal"
        >
            <div className="modal-heading">
                <div className="search-wrapper">
                    <Input
                        placeholder="Select your country"
                        variant="search"
                        onChange={handleSearch}
                    />
                </div>
                <p className="descr">Based on your country of citizenship, available currencies and payment methods may vary</p>
            </div>

            <SelectItems
                selectedOption={selectedCountry}
                selectList={options || cardCountriesList}
                handleOptionSelect={onSelect}
            />
        </Modal>
    );
};
