import cx from 'classnames';
import React, {useMemo} from 'react';
import {ACTION_TYPES, CURRENCY_SYMBOLS} from '../constants';
import _isEmpty from 'lodash/isEmpty';
import {useSelector} from 'react-redux';

export const OrderDetails = (props) => {
    const {
        rateDetails,
        actionType,
        accordionExpanded,
        selectedFiat,
        selectedCrypto,
        hasLimitsError = false,
        noAmounts = false,
    } = props;

    const cardCurrencies = useSelector(state => state.mercuryoWidgetReducer.currencies);
    const widgetRates = useSelector(state => state.mercuryoWidgetReducer.rates);

    const orderAmountText = useMemo(() => {
        const isBuyAction = actionType === ACTION_TYPES.BUY;
        const textObj = {
            from: null,
            to: null,
        };

        let fiatTotalAmount = rateDetails?.fiat_amount || '0';
        const fiatCurrency = rateDetails?.fiat_currency || selectedFiat?.name || 'USD';
        let cryptoTotalAmount = rateDetails?.amount || '0';
        const cryptoCurrency = rateDetails?.currency || selectedCrypto?.name || 'BTC';


        const displayOptions = cardCurrencies?.displayOptions;
        const displayDigits = displayOptions[cryptoCurrency]?.display_digits;
        const decimalPointIndex = cryptoTotalAmount.indexOf('.');
        if(decimalPointIndex > 0 && cryptoTotalAmount.substring(decimalPointIndex+1).length > displayDigits) {
            cryptoTotalAmount = cryptoTotalAmount.substring(0, decimalPointIndex + displayDigits + 1);
        }

        if(!hasLimitsError && !noAmounts) {
            const fiat1 = CURRENCY_SYMBOLS[fiatCurrency] || fiatTotalAmount;
            const fiat2 = CURRENCY_SYMBOLS[fiatCurrency] ? fiatTotalAmount : ' ' + fiatCurrency;

            if(isBuyAction) {
                textObj.from = <span className="amount">{cryptoTotalAmount}<span className="mx">{cryptoCurrency}</span></span>;
                textObj.to = <span className="amount-for">for <b className="darker">{fiat1}{fiat2}</b></span>
            } else {
                textObj.from = <span className="amount"><b className="darker">{fiat1}<span>{fiat2}</span></b>&nbsp;</span>;
                textObj.to = <span className="amount-for">for {cryptoTotalAmount} {cryptoCurrency}</span>
            }
        } else {
            const {buy = {}, sell = {}} = widgetRates || {};
            const fiatAmount = isBuyAction ? (buy[cryptoCurrency] && buy[cryptoCurrency][fiatCurrency]) : (sell[cryptoCurrency] && sell[cryptoCurrency][fiatCurrency]);

            const normalizedFiat = fiatAmount && fiatAmount?.includes('.') && fiatAmount?.substring(0, fiatAmount.indexOf('.') + 3);
            const fiatRate = normalizedFiat?.substring(0, ) || 0;

            const fiat1 = CURRENCY_SYMBOLS[fiatCurrency] || fiatRate;
            const fiat2 = CURRENCY_SYMBOLS[fiatCurrency] ? fiatRate : ' ' + fiatCurrency;

            textObj.from = <span className="amount">1 <span>{cryptoCurrency}</span>&nbsp;</span>;
            textObj.to = <span className="amount-for">= {fiat1} {fiat2}</span>
        }

        return textObj;
    }, [actionType, rateDetails, selectedFiat, selectedCrypto, hasLimitsError, noAmounts, widgetRates]);

    return (
        <div className="flexed-c">
            <div className="accordion__item__title">Your order</div>
            <div className="accordion__item__wrapper">
                {
                    (!_isEmpty(rateDetails) || !_isEmpty(widgetRates)) && selectedFiat && selectedCrypto ? (
                        <>
                            <div className="content">
                                {orderAmountText.from}
                                {orderAmountText.to}
                            </div>
                            {
                                !hasLimitsError && !noAmounts && (
                                    <svg width="8px" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"
                                         className={cx('arrow', {
                                             'transformed' : accordionExpanded
                                         })}
                                    >
                                        <path d="M6.8 0H1.2a.5.5 0 00-.35.85l2.8 2.8c.2.2.5.2.7 0l2.8-2.8A.5.5 0 006.79 0z"
                                              fill="var(--color-main-text, #232323)"></path>
                                    </svg>
                                )
                            }
                        </>
                    ) : (
                        <div className="content">
                            <span className="amount">1 BTC = <span className="mx">{CURRENCY_SYMBOLS['USD']}0</span></span>
                        </div>
                    )
                }
            </div>
        </div>
    );
};