import React, {useState} from 'react';
import {MercuryoWidget} from './components/MercuryoWidget';

export const MercuryoWidgetContainer = (props) => {
    const [widgetStarted, setWidgetStarted] = useState(false);
    const [widgetConfigReady, setWidgetConfigIsReady] = useState(false);

    return (
        <div className={!(widgetStarted && widgetConfigReady) ? 'container' : 'widget-container'} id="mercuryo-widget">
            <MercuryoWidget
                widgetStarted={widgetStarted}
                setWidgetStarted={setWidgetStarted}
                widgetConfigReady={widgetConfigReady}
                setWidgetConfigIsReady={setWidgetConfigIsReady}
            />
        </div>
    );
}