import React, {useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import {Header} from './components/Common/Header';
import {Sidebar} from './components/Common/Sidebar';
import {Provider} from 'react-redux';
import { store } from './app/store/store';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import {MercuryoWidgetContainer} from './app/components/MercuryoWidget';

function App() {
    const [showSidebar, setShowSidebar] = useState(false);

    const setOpenSidebar = (isShown) => {
        setShowSidebar(isShown)
    };

    return (
        <Provider store={store} >
            <BrowserRouter>
                <main className="main-theme main-theme--dark">
                    <Sidebar
                        active={showSidebar}
                        showSidebar={showSidebar}
                        setOpenSidebar={setOpenSidebar}
                    />
                    <section className="section section--scrypton">
                        <Header
                            showSidebar={showSidebar}
                            setOpenSidebar={setOpenSidebar}
                        />
                        <MercuryoWidgetContainer />
                    </section>
                </main>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
