import React from 'react';
import arrowDown from '../../../assets/images/arrow-down-bold.svg';
import {BTN_ICON} from '../Button/Button';
import {Button} from '../Button';
import './SelectButton.scss';
import cx from 'classnames';

export const SelectButton = (props) => {
    const {
        option,
        clickHandler,
        children,
        className,
    } = props;

    return (
        <Button
            btnType={BTN_ICON}
            className={cx('select-btn', className)}
            onClick={clickHandler}
        >
            {!children ? (
                <>
                    <img width="24" height="24" src={option.img} className="select-btn__img" alt="Burger"/>
                    <span className="select-btn__text">{option.cca2}</span>
                    {/*<img width="13" height="14" src={arrowDown} alt="Arrow"/>*/}
                    <svg viewBox="0 0 6 3" fill="none" width="6" height="6" xmlns="http://www.w3.org/2000/svg" className="arrow">
                        {/*<circle cx="5.5" cy="5.5" r="5" stroke="var(--color-main-secondary-text, #9E9E9E)"></circle>*/}
                        <path d="M4.8 0H1.2a.5.5 0 00-.35.85l1.8 1.8c.2.2.5.2.7 0l1.8-1.8A.5.5 0 004.79 0z"
                              fill="#ffffff"></path>
                    </svg>
                </>
            ) : children}
        </Button>
    );
}
