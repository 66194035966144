import {Field, Form, Formik} from 'formik';
import {Input} from '../../../../components/Material/Input';
import {Button} from '../../../../components/Material/Button';
import {BTN_PRIMARY} from '../../../../components/Material/Button/Button';
import {OrderDetailsAccordion} from './OrderDetailsAccordion';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {SelectButton} from '../../../../components/Material/SelectButton';
import {ACTION_TYPES, CURRENCY_TYPES} from '../constants';
import {CurrenciesModal} from './CurrenciesModal';
import {Slide, toast, ToastContainer} from 'react-toastify';
import {useSelector} from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import {usePrevious} from '../../../../Hooks/usePrevious';

export const CryptoBuyForm = (props) => {
    const {
        cardCurrencies = {},
        selectedCrypto,
        selectedFiat,
        fiatAmount,
        cryptoAmount,
        actionType,
        setSelectedCrypto,
        setSelectedFiat,
        handleSubmit,
        buyRate,
        sellRate,
        handleGetBuyRate,
        handleGetSellRate,
        setFiatAmount,
        setCryptoAmount,
        handleGetCurrencyLimits,
        widgetRates,
        initialAmounts,
    } = props;

    const {
        crypto,
        fiat,
        displayOptions,
    } = cardCurrencies;

    const [currencyModalOpen, setCurrencyModalOpen] = useState(false);
    const [currencyModalType, setCurrencyModalType] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [isUnsupportedCurrency, setIsUnsupportedCurrency] = useState(false);
    const [changedCurrency, setChangedCurrency] = useState({});
    const [typingTimerId, setTypingTimerId] = useState(null);
    const [typingTimerExpired, setTypingTimerExpired] = useState(false);

    const buyCurrencies = useSelector(state => state.mercuryoWidgetReducer.buyCurrencies);
    const sellCurrencies = useSelector(state => state.mercuryoWidgetReducer.sellCurrencies);
    const getBuyRateLoading = useSelector(state => state.mercuryoWidgetReducer.getBuyRateLoading);
    const getBuyRateSuccess = useSelector(state => state.mercuryoWidgetReducer.getBuyRateSuccess);
    const getSellRateLoading = useSelector(state => state.mercuryoWidgetReducer.getSellRateLoading);
    const getSellRateSuccess = useSelector(state => state.mercuryoWidgetReducer.getSellRateSuccess);
    const currencyLimits = useSelector(state => state.mercuryoWidgetReducer.currencyLimits);
    const getCurrencyLimitsLoading = useSelector(state => state.mercuryoWidgetReducer.getCurrencyLimitsLoading);

    const prevGetBuyRateLoading = usePrevious(getBuyRateLoading);
    const prevGetSellRateLoading = usePrevious(getSellRateLoading);

    const isBuyAction = actionType === ACTION_TYPES.BUY;

    useEffect(() => {
        if(initialAmounts.fiat && initialAmounts.crypto) {
            setChangedCurrency({
               type: CURRENCY_TYPES.FIAT,
               amount: initialAmounts.fiat,
            });
        }
    }, [initialAmounts]);

    useEffect(() => {
        if(!getCurrencyLimitsLoading && !_isEmpty(currencyLimits)) {
            const errors = validateCurrencyLimits();

            setValidationErrors(errors);

            if(_isEmpty(errors) && !!+changedCurrency?.amount && !(typingTimerId && !typingTimerExpired)) {
                handleGetRate({amount: changedCurrency?.amount});
            }
        }
    }, [
        cryptoAmount,
        selectedCrypto,
        fiatAmount,
        selectedFiat,
        currencyLimits,
        getCurrencyLimitsLoading,
        changedCurrency,
        typingTimerExpired,
        typingTimerId,
    ]);

    useEffect(() => {
        if(prevGetBuyRateLoading && !getBuyRateLoading && getBuyRateSuccess && !_isEmpty(buyRate)) {
            if(+fiatAmount || +cryptoAmount) {
                if(fiatAmount !== buyRate?.fiat_amount) {
                    if(!!+fiatAmount || (changedCurrency?.type !== CURRENCY_TYPES.FIAT)) {
                        let amount = buyRate?.fiat_amount;
                        setFiatAmount(amount);
                    }
                }

                if(cryptoAmount !== buyRate?.amount) {
                    if(!!+cryptoAmount || (changedCurrency?.type !== CURRENCY_TYPES.CRYPTO)) {
                        let amount = buyRate?.amount;
                        const decimalPointIndex = amount.indexOf('.');
                        const totalDigits = displayOptions[buyRate?.currency]?.total_digits;
                        if(decimalPointIndex > 0 && amount.substring(decimalPointIndex+1) > totalDigits) {
                            amount = amount.substring(0, decimalPointIndex + totalDigits + 1);
                            amount = Number(amount) + '';
                        }
                        setCryptoAmount(amount);
                    }
                }
            }
        }
    }, [prevGetBuyRateLoading, getBuyRateLoading, getBuyRateSuccess, buyRate]);

    useEffect(() => {
        if(prevGetSellRateLoading && !getSellRateLoading && getSellRateSuccess && !_isEmpty(sellRate)) {
            if(+fiatAmount || +cryptoAmount) {
                if(fiatAmount !== sellRate?.fiat_amount) {
                    if(!!+fiatAmount || (changedCurrency?.type !== CURRENCY_TYPES.FIAT)) {
                        setFiatAmount(sellRate?.fiat_amount);
                    }
                }

                if(cryptoAmount !== sellRate?.amount) {
                    if(!!+cryptoAmount || (changedCurrency?.type !== CURRENCY_TYPES.CRYPTO)) {
                        let amount = sellRate?.amount;
                        const decimalPointIndex = amount.indexOf('.');
                        const totalDigits = displayOptions[sellRate?.currency]?.total_digits;
                        if(decimalPointIndex > 0 && amount.substring(decimalPointIndex+1) > totalDigits) {
                            amount = amount.substring(0, decimalPointIndex + totalDigits + 1);
                            amount = Number(amount) + '';
                        }
                        setCryptoAmount(amount);
                    }
                }
            }
        }
    }, [prevGetSellRateLoading, getSellRateLoading, getSellRateSuccess, sellRate]);

    useEffect(() => {
        checkIsSupported();

        handleGetCurrencyLimits({
            from: selectedFiat?.name,
            to: selectedCrypto?.name,
            is_total: isBuyAction,
        });

        setChangedCurrency({
            type: CURRENCY_TYPES.FIAT,
            amount: fiatAmount,
        });
    }, [actionType]);

    const handleGetRate = (options) => {
        const {
            amount,
        } = options || {};

        if(isUnsupportedCurrency || (!+fiatAmount && !+cryptoAmount)) {
            return;
        }

        const isChangedFiat = changedCurrency.type === CURRENCY_TYPES.FIAT;
        let isTotalAmount = isBuyAction ? isChangedFiat : !isChangedFiat;

        let normalizedAmount;
        if(amount) {
            normalizedAmount = amount.endsWith('.') ? amount.substring(0, amount.length - 1) : amount;
        } else {
            let currAmount = isChangedFiat ? fiatAmount : cryptoAmount;
            normalizedAmount = currAmount.endsWith('.') ? currAmount.substring(0, currAmount.length - 1) : currAmount;
        }

        if(!+normalizedAmount) {
            return;
        }

        if(isBuyAction) {
            handleGetBuyRate({
                from: isTotalAmount ? selectedFiat?.name : selectedCrypto?.name,
                to: isTotalAmount ? selectedCrypto?.name : selectedFiat?.name,
                network: selectedCrypto?.networkName,
                amount: normalizedAmount,
                isTotal: isTotalAmount,
            });
        } else {
            handleGetSellRate({
                from: isTotalAmount ? selectedCrypto?.name : selectedFiat?.name,
                to: isTotalAmount ? selectedFiat?.name : selectedCrypto?.name,
                network: selectedCrypto?.networkName,
                amount: normalizedAmount,
                isTotal: isTotalAmount,
            });
        }
    }

    const handleGetRateRepeatedly = () => {
        if(_isEmpty(validationErrors)) {
            handleGetRate();
        }
    };

    const handleCryptoSelect = (item) => {
        setIsUnsupportedCurrency(false);

        handleGetCurrencyLimits({
            from: item?.name,
            to: selectedFiat?.name,
            is_total: !isBuyAction,
        });

        setSelectedCrypto(item);
        handleCurrencyModalClose();
    }

    const handleFiatSelect = (item) => {
        setIsUnsupportedCurrency(false);

        handleGetCurrencyLimits({
            from: item?.name,
            to: selectedCrypto?.name,
            is_total: isBuyAction,
        });

        setSelectedFiat(item);
        handleCurrencyModalClose();
    }

    const onFiatAmountChange = (event) => {
        onAmountChange(event, true);
    }

    const onCryptoAmountChange = (event) => {
        onAmountChange(event, false);
    }

    const onAmountChange = (event, isFiat) => {
        let amount = event?.target?.value.trim();

        if (amount.startsWith('.') || /[^0-9.]/.test(amount) || amount.split('.').length > 2 ||
            (isFiat && amount.length > 1 && amount.charAt(1) !== '.' && amount.startsWith('0')) || amount.startsWith('00')) {
            event.preventDefault();
            return;
        }

        const decimalPointIndex = amount.indexOf('.');
        if(decimalPointIndex !== amount.length - 1) {
            if(isFiat) {
                if(decimalPointIndex > 0 && amount.substring(decimalPointIndex+1).length > 2) {
                    amount = amount.substring(0, decimalPointIndex+3);
                }
            } else {
                const totalDigits = displayOptions[selectedCrypto.name]?.total_digits;
                if(decimalPointIndex > 0 && amount.substring(decimalPointIndex+1) > totalDigits) {
                    amount = amount.substring(0, decimalPointIndex + totalDigits + 1);
                }
            }
        }

        if(isFiat) {
            setFiatAmount(amount);
            setCryptoAmount('0');
            setChangedCurrency({
                amount: amount,
                type: CURRENCY_TYPES.FIAT,
            });
        } else {
            setCryptoAmount(amount);
            setFiatAmount('0');
            setChangedCurrency({
                amount: amount,
                type: CURRENCY_TYPES.CRYPTO,
            });
        }
    }

    const handleKeyUp = (e) => {
        if(typingTimerId) {
            clearTimeout(typingTimerId);
        }

        const timer = setTimeout(() => {
            setTypingTimerExpired(true);
        }, 1000);

        setTypingTimerExpired(false);
        setTypingTimerId(timer);
    };

    const validateCurrencyLimits = useCallback(() => {
        const errors = {};

        if(changedCurrency?.type === CURRENCY_TYPES.FIAT) {
            if(!+fiatAmount) {
                return errors;
            }

            const fiatMin = currencyLimits[selectedFiat?.name]?.min;
            const fiatMax = currencyLimits[selectedFiat?.name]?.max;

            if(+fiatAmount < +fiatMin) {
                errors.fiat = `Limits are off — min ${fiatMin} ${selectedFiat?.name}`
            }
            if(+fiatAmount > +fiatMax) {
                errors.fiat = `Limits are off — max ${fiatMax} ${selectedFiat?.name}`
            }

            if(validationErrors[CURRENCY_TYPES.CRYPTO]) {
                setCryptoAmount(0);
            }
        } else {
            if(!+cryptoAmount) {
                return errors;
            }

            const cryptoMin = currencyLimits[selectedCrypto?.name]?.min;
            const cryptoMax = currencyLimits[selectedCrypto?.name]?.max;

            if(+cryptoAmount < +cryptoMin) {
                errors.crypto = `Limits are off — min ${cryptoMin} ${selectedCrypto?.name}`
            }
            if(+cryptoAmount > +cryptoMax) {
                errors.crypto = `Limits are off — max ${cryptoMax} ${selectedCrypto?.name}`
            }

            if(validationErrors[CURRENCY_TYPES.CRYPTO]) {
                setFiatAmount(0);
            }
        }

        return errors;
    }, [changedCurrency, currencyLimits, selectedFiat, selectedCrypto, fiatAmount, cryptoAmount, validationErrors]);

    const checkIsSupported = () => {
        if(buyCurrencies.length && sellCurrencies.length && selectedFiat && selectedCrypto) {
            let availableCurrencies = isBuyAction ? buyCurrencies : sellCurrencies;
            const supportsSelectedFiat = availableCurrencies?.some(item => item === selectedFiat?.name);
            const supportsSelectedCrypto = availableCurrencies?.some(item => item === selectedCrypto?.name);

            if(!supportsSelectedFiat || !supportsSelectedCrypto) {
                notify(`Currency ${selectedFiat?.name || selectedCrypto?.name} is not supported.`)
                setIsUnsupportedCurrency(true);
            } else {
                setIsUnsupportedCurrency(false);
            }
        }
    }

    const notify = (message) => {
        toast(<>&nbsp;{message}</>);
    };

    const handleCurrencyModalClose = () => {
        setCurrencyModalType('');
        setCurrencyModalOpen(false);
    }

    const handleCurrencyModalOpen = (type) => {
        setCurrencyModalType(type);
        setCurrencyModalOpen(true);
    }

    const typesDetails = useMemo(() => (
        {
            [ACTION_TYPES.BUY]: {
                0: {
                    onChange: onFiatAmountChange,
                    openModal: () => handleCurrencyModalOpen('fiat'),
                    value: fiatAmount,
                    currency: selectedFiat?.name,
                    error: validationErrors?.fiat,
                },
                1: {
                    onChange: onCryptoAmountChange,
                    openModal: () => handleCurrencyModalOpen('crypto'),
                    value: cryptoAmount,
                    currency: selectedCrypto?.name,
                    error: validationErrors?.crypto,
                }
            },
            [ACTION_TYPES.SELL]: {
                0: {
                    onChange: onCryptoAmountChange,
                    openModal: () => handleCurrencyModalOpen('crypto'),
                    value: cryptoAmount,
                    currency: selectedCrypto?.name,
                    error: validationErrors?.crypto,
                },
                1: {
                    onChange: onFiatAmountChange,
                    openModal: () => handleCurrencyModalOpen('fiat'),
                    value: fiatAmount,
                    currency: selectedFiat?.name,
                    error: validationErrors?.fiat,
                }
            },
        }
    ), [fiatAmount, selectedFiat, validationErrors, cryptoAmount, selectedCrypto]);

    const noAmounts = useMemo(() => {
        return (!+fiatAmount && !+cryptoAmount);
    }, [fiatAmount, cryptoAmount]);

    const isSubmitDisabled = !_isEmpty(validationErrors) || (!+cryptoAmount) || (!+fiatAmount) || isUnsupportedCurrency || (typingTimerId && !typingTimerExpired);

    return (
        <>
            <Formik
                enableReinitialize={true}
                onSubmit={handleSubmit}
                initialValues={{}}
            >
                {({ isSubmitting }) => (
                    <Form
                        className="main-form"
                    >
                        <label className="regular-label" htmlFor="pay">
                            You {isBuyAction ? 'pay' : 'send'}
                        </label>
                        <div className={cx(
                            {
                                'has-error' : typesDetails[actionType][0]?.error
                            }
                        )}>
                            <div className="form-control-wrapper">
                                <Field
                                    id="pay"
                                    name="pay"
                                    type="text"
                                    pattern="[0-9]*\.?[0-9]*"
                                    placeholder="0"
                                    variant="big"
                                    className="form-control form-control--big"
                                    onChange={typesDetails[actionType][0]?.onChange}
                                    value={typesDetails[actionType][0]?.value}
                                    component={Input}
                                    onInput={handleKeyUp}
                                />
                                <SelectButton
                                    clickHandler={typesDetails[actionType][0]?.openModal}
                                >
                                    <div className='thumbnail symbol-bg'>
                                        <h4 className="sign"><span>{typesDetails[actionType][0]?.currency}</span></h4>
                                    </div>
                                </SelectButton>
                            </div>
                            {typesDetails[actionType][0]?.error ? <p className="error-message pos">{typesDetails[actionType][0]?.error}</p> : null}
                        </div>
                        <label className="regular-label" htmlFor="get">You get</label>
                        <div className={cx(
                            {
                                'has-error' : typesDetails[actionType][1]?.error
                            }
                        )}>
                           <div className="form-control-wrapper">
                               <Field
                                   id="get"
                                   name="get"
                                   type="text"
                                   pattern="[0-9]*\.?[0-9]*"
                                   placeholder="0"
                                   variant="big"
                                   className="form-control form-control--big"
                                   onChange={typesDetails[actionType][1]?.onChange}
                                   value={typesDetails[actionType][1]?.value}
                                   component={Input}
                                   onInput={handleKeyUp}
                               />
                               <SelectButton
                                   clickHandler={typesDetails[actionType][1]?.openModal}
                               >
                                   <div className='thumbnail symbol-bg'>
                                       <h4 className="sign"><span>{typesDetails[actionType][1]?.currency}</span>
                                           {selectedCrypto?.icon ? <sup className="crypto-icon"><img src={selectedCrypto.icon} alt="icon"/></sup> : null}
                                       </h4>
                                   </div>
                               </SelectButton>
                           </div>
                           {typesDetails[actionType][1]?.error ? <p className="error-message pos">{typesDetails[actionType][1]?.error}</p> : null}
                       </div>

                        <OrderDetailsAccordion
                            fiatAmount={fiatAmount}
                            selectedCrypto={selectedCrypto}
                            selectedFiat={selectedFiat}
                            rateDetails={isBuyAction ? buyRate : sellRate}
                            handleGetRate={handleGetRateRepeatedly}
                            actionType={actionType}
                            isUnsupportedCurrency={isUnsupportedCurrency}
                            hasLimitsError={!_isEmpty(validationErrors)}
                            noAmounts={noAmounts}
                            widgetRates={widgetRates}
                        />

                        <div className="form-actions">
                            <Button
                                disabled={isSubmitting || isSubmitDisabled}
                                btnType={BTN_PRIMARY}
                                className="submit-btn"
                                type={'submit'}
                            >
                                <span className="regular-text">{isBuyAction ? 'Buy' : 'Sell'}</span>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>

            <div>
                <ToastContainer
                    position="top-center"
                    autoClose={false}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                    transition={Slide}
                />
            </div>

            {
                !!currencyModalType && (
                    <CurrenciesModal
                        cryptoOptions={crypto}
                        fiatOptions={fiat}
                        currencyModalOpen={currencyModalOpen}
                        closeCurrencyModal={handleCurrencyModalClose}
                        handleCryptoSelect={handleCryptoSelect}
                        handleFiatSelect={handleFiatSelect}
                        currencyModalType={currencyModalType}
                        actionType={actionType}
                    />
                )
            }
        </>
    );
};
