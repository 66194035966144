import React from 'react';

export const CustomWidgetBottom = (props) => {
    return (
        <footer>
            <svg width="178" height="28" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="Amount__pay-methods">
                <path
                    d="M14.93 8.47L9.6 20.57H6.26l-2.4-9.66c-.15-.59-.28-.8-.74-1.06-.76-.4-2.01-.79-3.12-1.03l.08-.35h5.4c.7 0 1.3.45 1.45 1.24l1.24 7 3.42-8.24h3.34zm13.03 8.14c.06-3.19-4.43-3.36-4.38-4.79.02-.43.44-.9 1.36-1.01.46-.06 1.71-.1 3.13.54l.59-2.56a8.59 8.59 0 00-2.96-.54c-3.14 0-5.38 1.64-5.43 4-.04 1.74 1.54 2.7 2.74 3.29 1.23.59 1.64.97 1.63 1.5-.03.81-1 1.17-1.92 1.18-1.6.03-2.52-.42-3.25-.76l-.61 2.65c.73.33 2.1.63 3.52.64 3.33 0 5.54-1.62 5.58-4.14zm8.24 3.95h2.93L36.74 8.47h-2.71c-.61 0-1.13.35-1.36.89l-4.93 11.2h3.34l.68-1.8h4.08l.36 1.8zm-3.49-4.28l1.74-4.54.9 4.54H32.7zm-13.25-7.8l-2.8 12.08H13.5l2.8-12.09h3.17z"
                    fill="#1434CB"></path>
                <path d="M62.63 6.92h-7.87v14.16h7.87V6.92z" fill="#FF5F00"></path>
                <path d="M55.26 14a8.98 8.98 0 013.44-7.08 9 9 0 100 14.16A8.98 8.98 0 0155.26 14z"
                      fill="#EB001B"></path>
                <path
                    d="M73.26 14a9 9 0 01-14.56 7.08 9 9 0 000-14.16A9 9 0 0173.26 14zM72.4 19.58v-.3h.11v-.05h-.3v.06h.12v.29h.07zm.58 0v-.35h-.1l-.1.24-.1-.24h-.1v.35h.07v-.27l.1.23h.06l.1-.23v.27h.07z"
                    fill="#F79E1B"></path>
                <path
                    d="M105.2 5H81.52a12.39 12.39 0 00-.77.05 1.83 1.83 0 00-.95.48 1.69 1.69 0 00-.48.93 3.45 3.45 0 00-.05.77v13.55a11.55 11.55 0 00.05.76 1.76 1.76 0 00.48.93 1.7 1.7 0 00.95.48 3.66 3.66 0 00.78.05h23.98a14.67 14.67 0 00.78-.05 1.83 1.83 0 00.94-.48 1.7 1.7 0 00.49-.93 3.47 3.47 0 00.05-.77V7.52v-.29-.24c-.01-.18-.02-.36-.05-.53a1.76 1.76 0 00-.49-.93 1.73 1.73 0 00-.94-.48 3.64 3.64 0 00-.78-.05h-.3z"
                    fill="#232323"></path>
                <path
                    d="M105.2 5.6h.3a11.7 11.7 0 01.67.04 1.11 1.11 0 01.94.92 2.93 2.93 0 01.04.67V21c0 .13-.02.29-.04.44a1.16 1.16 0 01-.32.6 1.1 1.1 0 01-.62.32 3.13 3.13 0 01-.68.04H81.52a12.56 12.56 0 01-.68-.04 1.2 1.2 0 01-.62-.31 1.08 1.08 0 01-.31-.61 2.92 2.92 0 01-.04-.67V7.23a11.07 11.07 0 01.04-.67 1.17 1.17 0 01.31-.6 1.1 1.1 0 01.62-.32 3.08 3.08 0 01.68-.04H105.21"
                    fill="#fff"></path>
                <path
                    d="M87.14 11.05c.24-.3.4-.7.36-1.11a1.56 1.56 0 00-1.4 1.6c.4.04.79-.2 1.04-.49zM87.5 11.62c-.58-.03-1.07.33-1.34.33-.28 0-.7-.31-1.15-.3-.6 0-1.14.34-1.44.87-.62 1.07-.17 2.65.43 3.51.3.43.65.9 1.1.89.45-.02.62-.29 1.15-.29.53 0 .69.29 1.15.28.48-.01.78-.43 1.07-.86.33-.49.47-.96.48-.99 0 0-.93-.36-.93-1.41-.01-.89.72-1.3.75-1.33a1.64 1.64 0 00-1.28-.7zM92.5 10.43c1.24 0 2.11.86 2.11 2.11 0 1.26-.88 2.12-2.15 2.12h-1.38v2.2h-1v-6.43h2.42zm-1.42 3.4h1.15c.87 0 1.36-.47 1.36-1.28 0-.82-.5-1.28-1.36-1.28h-1.15v2.55zM94.87 15.53c0-.82.63-1.33 1.75-1.39l1.28-.07v-.37c0-.52-.35-.83-.94-.83-.56 0-.9.27-.99.69h-.91c.05-.85.78-1.47 1.94-1.47 1.13 0 1.86.6 1.86 1.54v3.23h-.92v-.77h-.03c-.27.52-.86.85-1.48.85-.91 0-1.56-.57-1.56-1.41zm3.03-.43v-.37l-1.15.08c-.58.04-.9.29-.9.7 0 .4.33.67.85.67.67 0 1.2-.46 1.2-1.08zM99.73 18.59v-.78l.31.01c.45 0 .69-.18.84-.66l.08-.3-1.7-4.7h1.05L101.5 16h.02l1.18-3.82h1.02l-1.76 4.94c-.4 1.13-.86 1.5-1.84 1.5l-.39-.02z"
                    fill="#232323"></path>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M139.7 5h-23.69a13.19 13.19 0 00-.77.05 1.83 1.83 0 00-.95.48 1.68 1.68 0 00-.48.93 3.48 3.48 0 00-.05.77v13.25a27.77 27.77 0 000 .53c0 .18.02.36.05.53a1.76 1.76 0 00.48.93 1.69 1.69 0 00.95.48 3.65 3.65 0 00.78.05H140a12.82 12.82 0 00.78-.05 1.83 1.83 0 00.94-.48 1.7 1.7 0 00.49-.93c.03-.17.04-.35.04-.53v-.24V7.52v-.29-.24c0-.18-.01-.36-.04-.53a1.76 1.76 0 00-.49-.93 1.73 1.73 0 00-.94-.48A3.62 3.62 0 00140 5h-.3z"
                      fill="#3C4043"></path>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M139.7 5.6h.3a10.94 10.94 0 01.68.04c.12.02.23.06.34.1a1.1 1.1 0 01.59.82 2.93 2.93 0 01.04.67v13.54a8.8 8.8 0 01-.04.66 1.16 1.16 0 01-.32.62 1.2 1.2 0 01-.62.31 3.13 3.13 0 01-.67.04h-23.98a13.5 13.5 0 01-.67-.04 1.1 1.1 0 01-.94-.92 2.95 2.95 0 01-.04-.67V7.23a8.85 8.85 0 01.04-.67 1.17 1.17 0 01.31-.61 1.12 1.12 0 01.63-.31 3.08 3.08 0 01.67-.04h23.69z"
                      fill="#FFFFFE"></path>
                <path
                    d="M127.4 14.42v2.56h-.8v-6.33h2.13c.5-.01 1 .18 1.37.55.73.69.77 1.84.09 2.58l-.1.1a1.9 1.9 0 01-1.37.54h-1.32zm0-3v2.22h1.34c.3 0 .6-.11.8-.33.42-.44.4-1.15-.03-1.57-.2-.2-.48-.31-.76-.31h-1.35zM132.52 12.5c.6 0 1.06.17 1.4.49.35.31.52.76.52 1.32v2.67h-.77v-.6h-.03c-.33.5-.77.74-1.33.74a1.7 1.7 0 01-1.18-.42c-.3-.27-.48-.65-.47-1.06 0-.45.17-.8.5-1.07.34-.27.79-.4 1.34-.4.48 0 .87.09 1.18.26v-.19a.96.96 0 00-.33-.72 1.21 1.21 0 00-1.83.28l-.7-.45c.37-.56.94-.84 1.7-.84zm-1.03 3.15c0 .21.1.41.26.53.18.14.4.22.62.21.34 0 .67-.13.9-.37.27-.26.4-.56.4-.9-.24-.2-.6-.3-1.04-.3-.33 0-.6.08-.82.24a.71.71 0 00-.32.6z"
                    fill="#3C4043"></path>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M138.84 12.65l-2.68 6.23h-.82l1-2.18-1.77-4.05h.88l1.27 3.1h.01l1.24-3.1h.87z"
                      fill="#3C4043"></path>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M124.16 13.86c0-.25-.02-.5-.06-.74h-3.38v1.4h1.94c-.08.45-.34.85-.72 1.1v.92h1.15a3.57 3.57 0 001.07-2.68z"
                      fill="#4285F4"></path>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M120.72 17.41a3.4 3.4 0 002.38-.87l-1.16-.91a2.15 2.15 0 01-3.23-1.15h-1.2v.93c.62 1.23 1.86 2 3.21 2z"
                      fill="#34A853"></path>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M118.7 14.48a2.21 2.21 0 010-1.4v-.93h-1.18a3.65 3.65 0 000 3.26l1.19-.93z"
                      fill="#FBBC04"></path>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M120.72 11.58c.51 0 1 .19 1.37.55l1.03-1.04a3.43 3.43 0 00-2.4-.95c-1.35 0-2.6.78-3.2 2l1.19.95a2.15 2.15 0 012.01-1.5z"
                      fill="#EA4335"></path>
                <path
                    d="M151.58 5.3h22.18c.62 0 1.07 0 1.42.03.34.03.56.1.74.2.26.14.48.36.63.63.1.18.16.4.2.74.03.35.03.8.03 1.42v11.36c0 .62 0 1.07-.03 1.42-.04.34-.1.56-.2.74a1.7 1.7 0 01-.63.64c-.18.1-.4.16-.74.19-.35.03-.8.03-1.42.03h-22.18c-.62 0-1.07 0-1.42-.03a1.77 1.77 0 01-.74-.2 1.7 1.7 0 01-.64-.63c-.1-.18-.16-.4-.19-.74-.03-.35-.03-.8-.03-1.42V8.32c0-.62 0-1.07.03-1.42.03-.34.09-.56.2-.74.14-.27.36-.49.63-.64.17-.1.4-.16.74-.19.35-.03.8-.03 1.42-.03z"
                    fill="#FBFBFB" stroke="#232323" strokeWidth=".6"></path>
                <path
                    d="M154.44 12.88l8-4.8 8 4.8M162.44 11.27v6.4M159.24 12.07v5.6M156.04 13.68v4M168.84 13.68v4M165.64 12.07v5.6M154.44 19.27h16"
                    stroke="#232323" strokeWidth=".8" strokeLinecap="round"></path>
            </svg>
            {/*<div className="by">Cryptopowered by <Link*/}
            {/*    to="https://mercuryo.io?utm_source=widget.mercuryo&amp;utm_medium=referral&amp;ref_code=&amp;utm_campaign=67710925-8b40-4767-846e-3b88db69f04d"*/}
            {/*    target="_blank" rel="noreferrer">*/}
            {/*    <svg viewBox="0 0 82 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="powered-by">*/}
            {/*        <g clip-path="url(#clip0_176_42769)">*/}
            {/*            <path*/}
            {/*                d="M75.31.5a4.03 4.03 0 00-4.24 4.19c0 2.3 1.58 4.18 4.24 4.18a4.02 4.02 0 004.24-4.18A4.02 4.02 0 0075.3.5zm0 7.37c-1.9 0-3.1-1.37-3.1-3.18.01-1.81 1.2-3.2 3.1-3.2 1.9 0 3.09 1.4 3.09 3.2 0 1.8-1.18 3.19-3.09 3.19zM38.16 1.51c1.48 0 2.54 1.02 2.6 2.09h1.11C41.8 2.05 40.45.5 38.21.5a4.02 4.02 0 00-4.25 4.2c0 2.26 1.46 4.18 4.32 4.18 1.71 0 2.97-.79 3.59-1.56l-.73-.65a4.06 4.06 0 01-2.86 1.2c-2.14 0-3.16-1.4-3.16-3.16 0-1.9 1.18-3.2 3.04-3.2zM50.49.78v3.38c0 2.42-1.45 3.72-3.06 3.72-1.34 0-2.07-.96-2.07-2.57V.77h-2.62v.95h1.51v3.86c0 1.91 1.1 3.3 3.1 3.3 1.55 0 2.6-.8 3.13-1.94V8.6h1.1V.77h-1.1zm18.76 0l-2.84 6.7-2.9-6.7h-2.16v.94h1.37l3.1 7.17c-.47 1.12-1.21 1.8-2.14 1.8a1.9 1.9 0 01-.92-.18v1.01c.35.13.71.19 1.08.18 1.34 0 2.26-.79 2.89-2.24L70.44.78h-1.19zM56.35 2.2V.78h-3.32v.94h2.2v5.94h-2.2v.94h6.01v-.94h-2.7V3.99c.22-1.28 1.36-2.47 2.75-2.47.49-.02.98.07 1.44.25v-1a2.54 2.54 0 00-1.2-.27 3.5 3.5 0 00-2.98 1.7zM29 2.2V.78h-3.33v.94h2.22v5.94h-2.22v.94h6.03v-.94H29V3.99c.2-1.28 1.34-2.47 2.75-2.47.49-.02.98.07 1.43.25v-1C32.8.6 32.38.5 31.97.5A3.52 3.52 0 0029 2.2zM14.07 7.66V3.69c0-1.8-1.15-3.19-2.88-3.19a3.07 3.07 0 00-2.94 1.97A2.82 2.82 0 005.52.5C4 .5 3.18 1.4 2.75 2.2V.77H0v.95h1.65V8.6h1.1V4c.11-.9.88-2.49 2.57-2.49 1.23 0 2 .7 2 2.5V8.6h1.1V3.96c.15-.88.88-2.45 2.55-2.45 1.25 0 1.99.7 1.99 2.5V8.6h2.36v-.94h-1.24zM20.47.5c-2.6 0-4.18 1.9-4.18 4.2 0 2.35 1.51 4.18 4.32 4.18 1.75 0 3-.85 3.5-1.52l-.73-.65a3.7 3.7 0 01-2.84 1.16 2.93 2.93 0 01-3.06-2.82h7.09l.02-.37c0-2.3-1.51-4.18-4.12-4.18zm2.96 3.6H17.5a2.9 2.9 0 012.97-2.6 2.87 2.87 0 012.96 2.6"*/}
            {/*                fill="currentColor"></path>*/}
            {/*        </g>*/}
            {/*        <defs>*/}
            {/*            <clipPath id="clip0_176_42769">*/}
            {/*                <path fill="#fff" transform="translate(0 .5)" d="M0 0h81.75v11.5H0z"></path>*/}
            {/*            </clipPath>*/}
            {/*        </defs>*/}
            {/*    </svg>*/}
            {/*</Link></div>*/}
        </footer>
    );
};
