import {Field, Form, Formik} from 'formik';
import {Input} from '../../../../components/Material/Input';
import {OrderDetailsAccordion} from './OrderDetailsAccordion';
import {Button} from '../../../../components/Material/Button';
import {BTN_PRIMARY} from '../../../../components/Material/Button/Button';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import {ACTION_TYPES} from '../constants';

export const WalletAddressForm = (props) => {
    const {
        address,
        selectedCrypto,
        selectedFiat,
        fiatAmount,
        setAddress,
        checkAddressValidity,
        handleSubmit,
        initialValues = {},
        handleGetBuyRate,
        buyRate,
        actionType,
        sellRate,
        handleGetSellRate,
    } = props;

    const [isValidAddress, setIsValidAddress] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const validateAddressLoading = useSelector(state => state.mercuryoWidgetReducer.validateAddressLoading);
    const validateAddressSuccess = useSelector(state => state.mercuryoWidgetReducer.validateAddressSuccess);
    const isAddressValid = useSelector(state => state.mercuryoWidgetReducer.isAddressValid);

    useEffect(() => {
        setValidationErrors({});
    }, [])

    useEffect(() => {
        if(!validateAddressLoading && validateAddressSuccess && isAddressValid && !isValidAddress && address.trim().length) {
            setIsValidAddress(true);
        } else if(!isAddressValid && isValidAddress){
            setIsValidAddress(false);
        }
    }, [
        validateAddressLoading,
        validateAddressSuccess,
        isAddressValid,
        address,
        isValidAddress,
    ]);

    useEffect(() => {
        if(!validateAddressLoading && validateAddressSuccess && address.trim().length) {
            if(!isAddressValid && !validationErrors?.wallet_address) {
                setValidationErrors({wallet_address: 'Invalid wallet address.'});
            }
            if(isAddressValid && !_isEmpty(validationErrors)) {
                setValidationErrors({});
            }
        }
    }, [isAddressValid, validateAddressLoading, validateAddressSuccess]);

    const handleInputChange = (event) => {
        const address = event?.target?.value.trim();
        validateAddress(address);
        setAddress(address);
    }

    const validateAddress = (address) => {
        if(!!address) {
            checkAddressValidity({
                address: address.trim(),
                currency: selectedCrypto?.name,
                network: selectedCrypto?.networkName,
            });
        } else {
            setIsValidAddress(false);
            setValidationErrors({});
        }
    }

    const handleGetRate = () => {
        if(actionType === ACTION_TYPES.BUY) {
            handleGetBuyRate({
                from: selectedFiat?.name,
                to: selectedCrypto?.name,
                network: selectedCrypto?.networkName,
            });
        } else {
            handleGetSellRate({
                from: selectedCrypto?.name,
                to: selectedFiat?.name,
                network: selectedCrypto?.networkName,
            });
        }
    }

    const isSubmitDisabled = !address.trim() || validateAddressLoading || !isValidAddress;
    const cryptoFullName = !selectedCrypto?.networkLabel ? selectedCrypto?.name :
        `${selectedCrypto?.name} ${selectedCrypto?.networkLabel}`;

    return (
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors, touched }) => {
                return (
                    <Form
                        className="main-form complete-form second-form"
                    >
                        <div className="headings">
                            <h1 className="complete-title">Enter wallet address</h1>
                            {
                                actionType === ACTION_TYPES.BUY ? (
                                    <p className="description">Where you want to send {selectedCrypto?.name}</p>
                                ) : (
                                    <p className="description">Where you want to sell {selectedCrypto?.name} from</p>
                                )
                            }
                        </div>

                        <div className={cx(
                            'pos-relative mb-30',
                            {
                                'has-error' : validationErrors?.wallet_address
                            }
                        )}>
                            <div className="wallet-input-container">
                                <Field
                                    name="wallet_address"
                                    type="text"
                                    placeholder=""
                                    component={Input}
                                    value={address}
                                    error={!!(errors.wallet_address && touched.wallet_address && validationErrors?.wallet_address)}
                                    onChange={handleInputChange}
                                />
                                {
                                    !address && (
                                        <div className="wallet-placeholder">
                                            <span>{cryptoFullName || 'BTC'} address or domain name </span>
                                            <svg width="22" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dh0Ca">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M19.78 3.28v6.06L2 16.52 19.78 3.28z" fill="var(--color-main-border, #E9E9E9)"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.45 3v10.48A5.54 5.54 0 0110.89 19a5.54 5.54 0 01-5.56-5.52V9.07l3.34-1.82v6.23c0 1.07.87 1.93 1.94 1.93 1.08 0 1.95-.86 1.95-1.93V5.12L16.45 3z" fill="var(--color-main-secondary-text, #B8B8B8)"></path>
                                            </svg>
                                        </div>
                                    )
                                }
                            </div>
                            {validationErrors?.wallet_address || touched.wallet_address ? <p className="error-message">{validationErrors?.wallet_address}</p> : null}
                        </div>

                        <OrderDetailsAccordion
                            fiatAmount={fiatAmount}
                            selectedCrypto={selectedCrypto}
                            selectedFiat={selectedFiat}
                            rateDetails={actionType === ACTION_TYPES.BUY ? buyRate : sellRate}
                            handleGetRate={handleGetRate}
                            actionType={actionType}
                        />

                        <div className="form-actions">
                            <Button
                                disabled={isSubmitting || isSubmitDisabled}
                                btnType={BTN_PRIMARY}
                                className="submit-btn"
                                type={'submit'}
                                loading={validateAddressLoading}
                            >
                                <span className="regular-text">Continue</span>
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
