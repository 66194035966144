import cx from 'classnames';
import {Button} from '../../../../components/Material/Button';
import {BTN_ICON} from '../../../../components/Material/Button/Button';
import React from 'react';
import {ACTION_TYPES, CURRENCY_SYMBOLS} from '../constants';
import _isEmpty from 'lodash/isEmpty';

export const OrderDetailsExpanded = (props) => {
    const {
        rateDetails,
        showInfoTooltip,
        showTooltip,
        infoTooltipRef,
        accordionExpanded,
        actionType,
        isUnsupportedCurrency = false,
    } = props;

    const isBuyAction = actionType === ACTION_TYPES.BUY;
    const fiatName = rateDetails?.fiat_currency;

    return (
        <ul className={cx(
            'accordion__expanded',
            {
                'expanded' : accordionExpanded
            }
        )}>
            {
                !_isEmpty(rateDetails) && rateDetails?.subtotal && (
                    <>
                        {
                            !isUnsupportedCurrency && (
                                <li>
                                    <span className="">1 {rateDetails?.currency} =</span>
                                    {
                                        CURRENCY_SYMBOLS[fiatName] ? (
                                            <span className="amount"> {CURRENCY_SYMBOLS[fiatName]}<span className="darker">{rateDetails?.rate}</span></span>
                                        ) : (
                                            <span className="amount"> {rateDetails?.rate} <span className="darker">{fiatName}</span></span>
                                        )
                                    }
                                </li>
                            )
                        }
                        <li>
                            <span className="">
                                {isBuyAction ? 'Pay for' : 'Sell'} {!isUnsupportedCurrency ? rateDetails.amount : '0'} {rateDetails.currency} {isBuyAction ? '' : 'for'}
                            </span>

                            {
                                CURRENCY_SYMBOLS[fiatName] ? (
                                    <span className="amount"> {CURRENCY_SYMBOLS[fiatName]}<span className="darker">{isBuyAction ? rateDetails?.subtotal[fiatName] : rateDetails?.total[fiatName]}</span></span>
                                ) : (
                                    <span className="amount"> {isBuyAction ? rateDetails?.subtotal[fiatName] : rateDetails?.total[fiatName]} <span className="darker">{fiatName}</span></span>
                                )
                            }
                        </li>
                    </>
                )
            }
            <li>
                <div className="fee-container">
                    <span> Fee </span>
                    <div className="tooltip-container">
                        <Button
                            btnType={BTN_ICON}
                            type='button'
                            className="transparent"
                            onClick={showInfoTooltip}
                        >
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" className="info">
                                <circle cx="8" cy="8" r="8" fill="var(--color-main-secondary-bg)"></circle>
                                <path d="M8 7.2v5.51" stroke="var(--color-action-active)" strokeWidth="1.8"></path>
                                <circle cx="8" cy="4.34" r="1.4" fill="var(--color-action-active)"></circle>
                            </svg>
                        </Button>
                        <div ref={infoTooltipRef} className={cx("custom-info-tooltip", {
                            'hidden': !showTooltip,
                        })}>
                            <p>The fees are necessary to process the transaction, they consist of:</p>
                            <p>Network fee is paid to crypto miners for processing blockchain transactions.</p>
                            <p>Mercuryo fee covers costs of payment processing and may vary depending on the payment method you choose.</p>
                        </div>
                    </div>
                </div>
                {
                   !_isEmpty(rateDetails) && (
                       CURRENCY_SYMBOLS[fiatName] ? (
                            <span className="amount"><span className="bigger"></span> {CURRENCY_SYMBOLS[fiatName]}<span className="darker">{!isUnsupportedCurrency ? (rateDetails.fee && rateDetails?.fee[fiatName]) : '--'}</span></span>
                        ) : (
                            <span className="amount"><span className="bigger"></span> {!isUnsupportedCurrency ? (rateDetails.fee && rateDetails?.fee[fiatName]) : '--'} <span className="darker">{fiatName}</span></span>
                        )
                    )
                }
            </li>
        </ul>
    );
};