import cx from 'classnames';
import {ACTION_TYPES, CUSTOM_STEPS} from '../constants';
import {SelectButton} from '../../../../components/Material/SelectButton';
import React from 'react';

export const CustomWidgetHeader = (props) => {
    const {
        actionType,
        setActionType,
        selectedCrypto,
        selectedCountry,
        setCountryModalOpen,
        isAddressValid,
        setAddress,
        setCurrentStep,
        isBuyCryptoStep,
    } = props;

    const isBuyAction = actionType === ACTION_TYPES.BUY;

    const handleActionTypeChange = (type) => {
        if(actionType === type) {
            return;
        }

        setActionType(type);
    }

    const handleBackFromWallet = () => {
        setAddress('');
        setCurrentStep(CUSTOM_STEPS.buy);
    }

    const showCountryModal = () => {
        setCountryModalOpen(true);
    };

    return (
        <header className="widget-header">
            <h1 className="flexed-wrapper page-title">
                {
                    isBuyCryptoStep ? (
                        <>
                            <button
                                type="button"
                                data-testid="amount_operation_buy"
                                className={cx("buy-sell-btn", {
                                    selected: isBuyAction,
                                })}
                                disabled=""
                                onClick={() => handleActionTypeChange(ACTION_TYPES.BUY)}
                            >
                                Buy {isBuyAction ? selectedCrypto?.name : ""}
                            </button>
                            <button
                                type="button"
                                data-testid="amount_operation_sell"
                                className={cx("buy-sell-btn", {
                                    selected: !isBuyAction,
                                })}
                                onClick={() => handleActionTypeChange(ACTION_TYPES.SELL)}
                            >
                                Sell {!isBuyAction ? selectedCrypto?.name : ""}
                            </button>
                        </>
                    ) : (
                        <div className="back-action" onClick={handleBackFromWallet}>
                            <svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 15L2 8l7-7M2 8h15" stroke="var(--color-main-text, #232323)" strokeWidth="1.6"></path>
                            </svg>
                        </div>
                    )
                }
            </h1>

            {
                selectedCountry && (
                    <SelectButton
                        className="custom-select-country"
                        option={selectedCountry}
                        clickHandler={showCountryModal}
                    />
                )
            }
        </header>
    );
}
