import React, {useEffect, useRef, useState} from 'react';
import {useOutsideClick} from '../../../../Hooks/useOutsideClick';
import '../Styles/Accordion.scss';
import {useSelector} from 'react-redux';
import {OrderDetails} from './OrderDetails';
import {RatesTimer} from './RatesTimer';
import {OrderDetailsExpanded} from './OrderDetailsExpanded';

export const OrderDetailsAccordion = (props) => {
    const {
        fiatAmount,
        selectedCrypto,
        selectedFiat,
        rateDetails,
        handleGetRate,
        actionType,
        isUnsupportedCurrency,
        hasLimitsError,
        noAmounts = false,
        widgetRates = {},
    } = props;


    const getBuyRateLoading = useSelector(state => state.mercuryoWidgetReducer.getBuyRateLoading);

    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [timer, setTimer] = useState(10);
    const [intervalId, setIntervalId] = useState('');

    const infoTooltipRef = useRef(null);

    useEffect(() => {
        if(selectedFiat && selectedCrypto && fiatAmount) {
            if(intervalId) {
                clearInterval(intervalId);
                setTimer(10);
            }

            const interval = setInterval(() => {
                setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 10));
            }, 1000);

            setIntervalId(interval);
        }
    }, [actionType, fiatAmount, selectedCrypto, selectedFiat]);

    useEffect(() => {
        if (timer === 0 && !hasLimitsError) {
            handleGetRate();
        }
    }, [timer]);

    const onAccordion = () => {
        setAccordionExpanded(!accordionExpanded);
    };

    const showInfoTooltip = () => {
        setShowTooltip(true);
    };

    const handleClickOutside = (event) => {
        if (!event.target.classList.contains('custom-info-tooltip')) {
            setShowTooltip(false);
        }
    };

    useOutsideClick(infoTooltipRef, handleClickOutside);

    return (
        <>
            <div className="accordion">
                <div
                    onClick={onAccordion}
                    role="button"
                    tabIndex="0"
                    className="accordion__item pointer"
                >
                    <OrderDetails
                        rateDetails={rateDetails}
                        actionType={actionType}
                        accordionExpanded={accordionExpanded}
                        selectedFiat={selectedFiat}
                        selectedCrypto={selectedCrypto}
                        hasLimitsError={hasLimitsError}
                        noAmounts={noAmounts}
                        widgetRates={widgetRates}
                    />
                    {
                        (!hasLimitsError && !noAmounts) && (
                            <RatesTimer
                                timer={timer}
                                getBuyRateLoading={getBuyRateLoading}
                            />
                        )
                    }
                </div>

                {
                    (!hasLimitsError && !noAmounts) && (
                        <OrderDetailsExpanded
                            rateDetails={rateDetails}
                            showInfoTooltip={showInfoTooltip}
                            showTooltip={showTooltip}
                            infoTooltipRef={infoTooltipRef}
                            accordionExpanded={accordionExpanded}
                            actionType={actionType}
                            selectedFiat={selectedFiat}
                            isUnsupportedCurrency={isUnsupportedCurrency}
                        />
                    )
                }
            </div>
        </>
    )
};
