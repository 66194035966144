import {
    GET_WIDGET_BUY_RATE_FAILURE,
    GET_WIDGET_BUY_RATE_REQUEST,
    GET_WIDGET_BUY_RATE_SUCCESS,
    GET_WIDGET_COUNTRIES_FAILURE,
    GET_WIDGET_COUNTRIES_REQUEST,
    GET_WIDGET_COUNTRIES_SUCCESS,
    GET_WIDGET_CURRENCIES_FAILURE,
    GET_WIDGET_CURRENCIES_REQUEST,
    GET_WIDGET_CURRENCIES_SUCCESS,
    GET_WIDGET_CURRENCY_LIMITS_FAILURE,
    GET_WIDGET_CURRENCY_LIMITS_REQUEST,
    GET_WIDGET_CURRENCY_LIMITS_SUCCESS,
    GET_WIDGET_SELL_RATE_FAILURE,
    GET_WIDGET_SELL_RATE_REQUEST,
    GET_WIDGET_SELL_RATE_SUCCESS,
    RESET_MERCURYO_WIDGET_STORE,
    VALIDATE_ADDRESS_FAILURE,
    VALIDATE_ADDRESS_REQUEST,
    VALIDATE_ADDRESS_SUCCESS,
    GET_WIDGET_BUY_CURRENCIES_REQUEST,
    GET_WIDGET_BUY_CURRENCIES_SUCCESS,
    GET_WIDGET_BUY_CURRENCIES_FAILURE,
    GET_WIDGET_SELL_CURRENCIES_REQUEST,
    GET_WIDGET_SELL_CURRENCIES_SUCCESS,
    GET_WIDGET_SELL_CURRENCIES_FAILURE,
    GET_WIDGET_USER_DATA_REQUEST,
    GET_WIDGET_USER_DATA_SUCCESS,
    GET_WIDGET_USER_DATA_FAILURE, GET_WIDGET_RATES_REQUEST, GET_WIDGET_RATES_SUCCESS, GET_WIDGET_RATES_FAILURE,
} from './constants';

const initialState = {
    currencies: {},
    getCurrenciesListLoading: false,
    getCurrenciesListSuccess: false,
    getCurrenciesListFailure: false,

    countriesList: [],
    getCountriesListLoading: false,
    getCountriesListSuccess: false,
    getCountriesListFailure: false,
    getCountriesDetailsListLoading: false,
    getCountriesDetailsListSuccess: false,
    getCountriesDetailsListFailure: false,

    currencyLimits: {},
    getCurrencyLimitsLoading: false,
    getCurrencyLimitsSuccess: false,
    getCurrencyLimitsFailure: false,

    rates: {},
    getWidgetRatesLoading: false,
    getWidgetRatesSuccess: false,
    getWidgetRatesFailure: false,

    buyRate: {},
    getBuyRateLoading: false,
    getBuyRateSuccess: false,
    getBuyRateFailure: false,

    sellRate: {},
    getSellRateLoading: false,
    getSellRateSuccess: false,
    getSellRateFailure: false,

    buyCurrencies: [],
    getBuyCurrenciesLoading: false,
    getBuyCurrenciesSuccess: false,
    getBuyCurrenciesFailure: false,

    sellCurrencies: [],
    getSellCurrenciesLoading: false,
    getSellCurrenciesSuccess: false,
    getSellCurrenciesFailure: false,

    userData: null,
    getUserDataLoading: false,
    getUserDataSuccess: false,
    getUserDataFailure: false,

    isAddressValid: false,
    validateAddressLoading: false,
    validateAddressSuccess: false,
    validateAddressFailure: false,
};

export default function mercuryoWidgetReducer(state = initialState, action) {
    switch (action.type) {
        case GET_WIDGET_CURRENCIES_REQUEST: {
            return Object.assign({}, state, {
                getCurrenciesListLoading: true,
                getCurrenciesListSuccess: false,
                getCurrenciesListFailure: false,
            });
        }
        case GET_WIDGET_CURRENCIES_SUCCESS: {
            return Object.assign({}, state, {
                getCurrenciesListLoading: false,
                getCurrenciesListSuccess: true,
                getCurrenciesListFailure: false,
                currencies: action.payload,
            });
        }
        case GET_WIDGET_CURRENCIES_FAILURE: {
            return Object.assign({}, state, {
                getCurrenciesListLoading: false,
                getCurrenciesListSuccess: false,
                getCurrenciesListFailure: true,
            });
        }

        case GET_WIDGET_COUNTRIES_REQUEST: {
            return Object.assign({}, state, {
                getCountriesListLoading: true,
                getCountriesListSuccess: false,
                getCountriesListFailure: false,
            });
        }
        case GET_WIDGET_COUNTRIES_SUCCESS: {
            return Object.assign({}, state, {
                getCountriesListLoading: false,
                getCountriesListSuccess: true,
                getCountriesListFailure: false,
                countriesList: action.payload,
            });
        }
        case GET_WIDGET_COUNTRIES_FAILURE: {
            return Object.assign({}, state, {
                getCountriesListLoading: false,
                getCountriesListSuccess: false,
                getCountriesListFailure: true,
            });
        }

        case GET_WIDGET_CURRENCY_LIMITS_REQUEST: {
            return Object.assign({}, state, {
                getCurrencyLimitsLoading: true,
                getCurrencyLimitsSuccess: false,
                getCurrencyLimitsFailure: false,
                currencyLimits: {},
            });
        }
        case GET_WIDGET_CURRENCY_LIMITS_SUCCESS: {
            return Object.assign({}, state, {
                getCurrencyLimitsLoading: false,
                getCurrencyLimitsSuccess: true,
                getCurrencyLimitsFailure: false,
                currencyLimits: action.payload?.data,
            });
        }
        case GET_WIDGET_CURRENCY_LIMITS_FAILURE: {
            return Object.assign({}, state, {
                getCurrencyLimitsLoading: false,
                getCurrencyLimitsSuccess: false,
                getCurrencyLimitsFailure: true,
            });
        }

        case GET_WIDGET_RATES_REQUEST : {
            return Object.assign({}, state, {
                getWidgetRatesLoading: true,
                getWidgetRatesSuccess: false,
                getWidgetRatesFailure: false,
            });
        }
        case GET_WIDGET_RATES_SUCCESS : {
            return Object.assign({}, state, {
                getWidgetRatesLoading: false,
                getWidgetRatesSuccess: true,
                getWidgetRatesFailure: false,
                rates: action.payload?.data,
            });
        }
        case GET_WIDGET_RATES_FAILURE : {
            return Object.assign({}, state, {
                getWidgetRatesLoading: false,
                getWidgetRatesSuccess: false,
                getWidgetRatesFailure: true,
            });
        }

        case GET_WIDGET_BUY_RATE_REQUEST : {
            return Object.assign({}, state, {
                getBuyRateLoading: true,
                getBuyRateSuccess: false,
                getBuyRateFailure: false,
            });
        }
        case GET_WIDGET_BUY_RATE_SUCCESS : {
            return Object.assign({}, state, {
                getBuyRateLoading: false,
                getBuyRateSuccess: true,
                getBuyRateFailure: false,
                buyRate: action.payload?.data,
            });
        }
        case GET_WIDGET_BUY_RATE_FAILURE : {
            return Object.assign({}, state, {
                getBuyRateLoading: false,
                getBuyRateSuccess: false,
                getBuyRateFailure: true,
            });
        }

        case GET_WIDGET_SELL_RATE_REQUEST : {
            return Object.assign({}, state, {
                getSellRateLoading: true,
                getSellRateSuccess: false,
                getSellRateFailure: false,
            });
        }
        case GET_WIDGET_SELL_RATE_SUCCESS : {
            return Object.assign({}, state, {
                getSellRateLoading: false,
                getSellRateSuccess: true,
                getSellRateFailure: false,
                sellRate: action.payload?.data,
            });
        }
        case GET_WIDGET_SELL_RATE_FAILURE : {
            return Object.assign({}, state, {
                getSellRateLoading: false,
                getSellRateSuccess: false,
                getSellRateFailure: true,
            });
        }

        case GET_WIDGET_BUY_CURRENCIES_REQUEST : {
            return Object.assign({}, state, {
                getBuyCurrenciesLoading: true,
                getBuyCurrenciesSuccess: false,
                getBuyCurrenciesFailure: false,
            });
        }
        case GET_WIDGET_BUY_CURRENCIES_SUCCESS : {
            return Object.assign({}, state, {
                getBuyCurrenciesLoading: false,
                getBuyCurrenciesSuccess: true,
                getBuyCurrenciesFailure: false,
                buyCurrencies: action.payload?.data,
            });
        }
        case GET_WIDGET_BUY_CURRENCIES_FAILURE : {
            return Object.assign({}, state, {
                getBuyCurrenciesLoading: false,
                getBuyCurrenciesSuccess: false,
                getBuyCurrenciesFailure: true,
            });
        }

        case GET_WIDGET_SELL_CURRENCIES_REQUEST : {
            return Object.assign({}, state, {
                getSellCurrenciesLoading: true,
                getSellCurrenciesSuccess: false,
                getSellCurrenciesFailure: false,
            });
        }
        case GET_WIDGET_SELL_CURRENCIES_SUCCESS : {
            return Object.assign({}, state, {
                getSellCurrenciesLoading: false,
                getSellCurrenciesSuccess: true,
                getSellCurrenciesFailure: false,
                sellCurrencies: action.payload?.data,
            });
        }
        case GET_WIDGET_SELL_CURRENCIES_FAILURE : {
            return Object.assign({}, state, {
                getSellCurrenciesLoading: false,
                getSellCurrenciesSuccess: false,
                getSellCurrenciesFailure: true,
            });
        }

        case GET_WIDGET_USER_DATA_REQUEST: {
            return Object.assign({}, state, {
                getUserDataLoading: true,
                getUserDataSuccess: false,
                getUserDataFailure: false,
            });
        }
        case GET_WIDGET_USER_DATA_SUCCESS: {
            return Object.assign({}, state, {
                userData: action?.payload?.data,
                getUserDataLoading: false,
                getUserDataSuccess: true,
                getUserDataFailure: false,
            });
        }
        case GET_WIDGET_USER_DATA_FAILURE: {
            return Object.assign({}, state, {
                getUserDataLoading: false,
                getUserDataSuccess: false,
                getUserDataFailure: true,
            });
        }

        case VALIDATE_ADDRESS_REQUEST: {
            return Object.assign({}, state, {
                validateAddressLoading: true,
                validateAddressSuccess: false,
                validateAddressFailure: false,
            });
        }
        case VALIDATE_ADDRESS_SUCCESS: {
            return Object.assign({}, state, {
                validateAddressLoading: false,
                validateAddressSuccess: true,
                validateAddressFailure: false,
                isAddressValid: action.payload?.data?.isValid,
            });
        }
        case VALIDATE_ADDRESS_FAILURE: {
            return Object.assign({}, state, {
                validateAddressLoading: false,
                validateAddressSuccess: false,
                validateAddressFailure: true,
            });
        }

        case RESET_MERCURYO_WIDGET_STORE: {
            return initialState;
        }
        default:
            return state;
    }
};