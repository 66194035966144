const JSON_PARSE_ERROR = 'Unable to parse the response.';

const _fetch = (urlParams, options = {}) => {
    const serverUrl =  process.env.REACT_APP_BASE_SERVER_URL;

    const url = serverUrl + urlParams;

    let statusCode;
    const returnData = {
        success: false,
        payload: {}
    };

    return fetch(url, options)
        .then(response => {
            if (response.ok) {
                const tokenHeader = response.headers.get('authorization');
                returnData.success = true;
                returnData.headers = response.headers;
            }
            statusCode = response.status;
            return response.json();
        })
        .catch(error => {
            returnData.success = false;
            return { error: JSON_PARSE_ERROR };
        })
        .then(jsonData => {
            if (returnData.success) {
                returnData.payload = jsonData;
            } else {
                returnData.error = statusCode;
            }
            return returnData;
        });
};

export const httpService = {
    get: (url, headers = {}) => {
        return _fetch(url, {
            headers,
        });
    }
}